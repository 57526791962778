import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Tabs, Tab, Modal } from "react-bootstrap";
import Slider from "react-slick";
import { Link } from "react-router-dom";

import { getProfile, getProfileWithPayload } from "../../actions/get-calls";
import { switchPatient } from "../../actions/post-call";
import { MemberProfileDelete } from "../../actions/dashboard-api";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import { setCallTokenReducer, clearCallTokenReducer } from "../../redux/slice/call-token";
import {
  setSwitchTokenReducer,
  clearSwitchTokenReducer,
} from "../../redux/slice/switch-token";
// import { setAllUsersReducer, clearAllUsersReducer } from "../../redux/slice/all-users";
import {
  setSelectedUserReducer,
  clearSelectedUserReducer,
} from "../../redux/slice/selected-user";
import {
  setUserProfileReducer,
  clearUserProfileReducer,
} from "../../redux/slice/user-profile";

import {
  setMemberProfileReducer,
  clearMemberProfileReducer,
} from "../../redux/slice/member-profile";
import {
  setSelectedUserCardReducer,
  clearSelectedUserCardReducer,
} from "../../redux/slice/selected-userCard";

import WalletBalnce from "./walletBalnce";
import InputDatePiker from "../datepiker/inputDatePiker";
import { calculateAge } from "../../utils/fliters";
import CallTOHelp from "../call-to-help";
import DashBoardFamilyMember from "../family-member/dashboardFamilyMember";
import { is_group_exist } from "../../utils/constant";
import LodingComponent from "../loding";

const diaTabs = {
  FamilyMemberY: "Yes",
  FamilyMemberN: "No",
};
const settingsprofile = {
  dots: false,
  infinite: false,
  speed: 1000,
  // slidesToShow: 6,
  slidesToScroll: 1,
  autoplay: false,
  variableWidth: true,
};

const settings = {
  dots: false,
  infinite: false,
  speed: 1000,
  slidesToShow: 4,
  slidesToScroll: 4,
  autoplay: false,

  responsive: [
    {
      breakpoint: 1199,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const SwitchProfile = (
  {
    // props = setMemberSelect,
  }
) => {
  const [callKey, setCallKey] = useState(null);
  const [switchKey, setSwitchKey] = useState(null);
  const [userList, setUserList] = useState(null);
  const [selectedUserProfile, setSelectedUserProfile] = useState(null);

  const [currentProfileId, setCurrentProfileId] = useState(null);
  const [currentUserId, setCurrentUserId] = useState(null);
  const [currentMemberId, setCurrentMemberId] = useState(null);

  const dispatch = useDispatch();
  const callToken = useSelector((state) => state.callToken.value);
  const switchToken = useSelector((state) => state.switchToken.value);
  const allUsers = useSelector((state) => state.allUsers.value);
  const selectedUser = useSelector((state) => state.selectedUser.value);
  const selectedUserCard = useSelector((state) => state.selectedUserCard.value);
  const currentLocation = useSelector((state) => state.currentLocation.value);

  const [fmailytab, setFmailytab] = useState(diaTabs.FamilyMemberY);
  const [modal, setModal] = useState(false);
  const [otpform, setOtpform] = useState(false);


  const handleShow = () => {
    setModal(true);
  };
  const handleClose = () => {
    setModal(false);
  };
  const handletabs = (e) => {
    setFmailytab(e.target.value);
  };
  const handleOtpSearch = () => {
    setOtpform(true);
  };

  useEffect(() => {
    if (callToken) {
      setCallKey(callToken);
    }
  }, [callToken]);

  useEffect(() => {
    if (switchToken) {
      setSwitchKey(switchToken);
    }
  }, [switchToken]);

  useEffect(() => {
    if (allUsers) {
      setUserList(allUsers);
    }
  }, [allUsers]);

  useEffect(() => {
    if (selectedUser) {
      setCurrentProfileId(selectedUser.id);
      setSelectedUserProfile(selectedUser);
      setCurrentUserId("currentActive_" + selectedUser.id);
      if (allUsers[0].id == selectedUser.id && !selectedUserCard) {
        
        dispatch(
          setSelectedUserCardReducer({
            card: "self",
            id: selectedUser.id,
            mhea_patient_id: selectedUser.mhea_patient_id,
            name: selectedUser.full_name,
          })
        );
      }
    }
  }, [selectedUser]);

  useEffect(() => {
    if (selectedUserCard) {
      setCurrentMemberId("memberActive_" + selectedUserCard.id);
    }
  }, [selectedUserCard]);

  function selectSwitch(id, mhea_id, name) {
    setCurrentProfileId(id);
    setCurrentUserId("currentActive_" + id);
    setCurrentMemberId("memberActive_" + id);
    dispatch(
      setSelectedUserCardReducer({
        card: "self",
        id: id,
        mhea_patient_id: mhea_id,
        name: name,
      })
    );


    let payload = new FormData();
    payload.append("patient_id", id);
    if(is_group_exist == true){
      payload.append("hospital_group_id", currentLocation?.hospital_group_id);
      payload.append("group_id", currentLocation?.hospital_group_id);
    }
    switchPatient(payload, callToken)
      .then(function (switch_response) {
        let switch_token = switch_response.headers.authorization;

        dispatch(setSwitchTokenReducer(switch_token));
        dispatch(setSelectedUserReducer(switch_response.data.user));

        getProfile(switch_token)
          .then(function (profile_response) {
            if (profile_response && profile_response.data) {
              dispatch(setUserProfileReducer(profile_response.data.user));
              // setMemberSelect(profile_response.data.user);
            }
          })
          .catch(function (err) {});
      })
      .catch(function (err) {});
  }

  function onSwitchMember(id, userType, mhea_id, name) {
    // console.log(id, userType)
    setCurrentMemberId("memberActive_" + id);
    dispatch(
      setSelectedUserCardReducer({
        card: userType,
        id: id,
        mhea_patient_id: mhea_id,
        name: name,
      })
    );
  }

  function myProfileHandler(obj) {
    dispatch(setMemberProfileReducer(obj));
  }

  function deleteMember(id) {
    MemberProfileDelete({ family_member_id: id }, switchToken)
      .then(function (response) {
        // console.log(response)

        if (allUsers.length == 1) {
          getProfile(switchToken)
            .then(function (profile_response) {
              if (profile_response && profile_response.data) {
                dispatch(setUserProfileReducer(profile_response.data.user));
                dispatch(setSelectedUserReducer(profile_response.data.user));
                // window.location.reload();
              }
            })
            .catch(function (err) {});
        } else {
          // switchUser(id, callToken);

          let payload = new FormData();
          payload.append("patient_id", selectedUser.id);
          if(is_group_exist == true){
            payload.append("hospital_group_id", currentLocation?.hospital_group_id);
            payload.append("group_id", currentLocation?.hospital_group_id);
          }

          switchPatient(payload,  callToken)
            .then(function (switch_response) {
              let switch_token = switch_response.headers.authorization;
              dispatch(setSwitchTokenReducer(switch_token));
              dispatch(setSelectedUserReducer(switch_response.data.user));
            })
            .catch(function (err) {});

          return showToast(response.data.message);
        }
      })
      .catch(function (err) {});
  }

  function showToast(str) {
    toast.success(str, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
    });
  }

  return callToken ? (
    <>
      <div className="mhc-dashboard-card  ">
        <div className="mhc-tabs mhc-tabs-borderless">
          {/* <WalletBalnce /> */}
          <div className="selectProfile">
            <div
              className={`profileList mb-3 profile-width ${
                userList?.length > 1 ? "" : ""
              }`}
            >
              <nav className="nav nav-pills nav-justified custom-nav">
                <Slider {...settingsprofile} className="border border-light">
                  {userList?.map((user) => (
                    <button
                      type="button"
                      className={`btn ${
                        currentUserId === "currentActive_" + user.id
                          ? "active"
                          : ""
                      }`}
                      key={user.id}
                      onClick={(k) => {
                        selectSwitch(
                          user.id,
                          user.mhea_patient_id,
                          user.full_name
                        );
                      }}
                    >
                      {user.full_name}
                    </button>
                  ))}
                </Slider>
              </nav>
            </div>
          </div>

          {selectedUserProfile != null ? (
            <div
              className={`${
                selectedUserProfile?.dependents.length > 0 ? "px-45" : ""
              }`}
            >
              <Slider {...settings} className="dahboard-slider">
                <div
                  className={`mhc-patient-card ${
                    "memberActive_" + selectedUserProfile.id == currentMemberId
                      ? "active"
                      : ""
                  }`}
                  onClick={() => {
                    onSwitchMember(
                      selectedUserProfile.id,
                      "self",
                      selectedUserProfile.mhea_patient_id,
                      selectedUserProfile.full_name
                    );
                  }}
                >
                  <div className="row align-items-center">
                    <div className="d-flex align-items-center">
                      <div className="img-holder">
                        <img
                          src={selectedUserProfile.profile_image}
                          alt={selectedUserProfile.full_name}
                        />
                      </div>
                      <div className="ms-2 w-100">
                        <p>{selectedUserProfile.full_name}</p>
                        <div className="row">
                          <div className="col-9">
                            <span>
                              {selectedUserProfile.gender},{" "}
                              {calculateAge(selectedUserProfile.dob)} (Self)
                            </span>
                          </div>
                          <div className="col-3 text-end">
                            <Link to={`/my-profile?self`}>
                              <i className="mhc-icon icon-pencil text-primary fs-13 cursor-pointer me-2 me-md-0"></i>
                            </Link>
                            {/* <button><i className="mhc-icon icon-pencil text-primary fs-13 cursor-pointer"></i></button> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {selectedUserProfile.dependents.map((member, i) => (
                  <div
                    key={member.id}
                    className={`mhc-patient-card ${
                      "memberActive_" + member.id == currentMemberId
                        ? "active"
                        : ""
                    }`}
                    onClick={() => {
                      onSwitchMember(
                        member.id,
                        "member",
                        member.mhea_patient_id,
                        member.full_name
                      ); /* setMemberSelect(member) */
                    }}
                  >
                    <div className="row align-items-center">
                      <div className="d-flex align-items-center">
                        <div className="img-holder">
                          <img
                            src={member.profile_image}
                            alt={member.full_name}
                          />
                        </div>
                        <div className="ms-2 w-100">
                          <p>{member.full_name}</p>
                          <div className="row align-items-center">
                            <div className="col-8 col-sm-9 col-md-9 ">
                              <span>
                                {member.gender}, {calculateAge(member.dob)} (
                                {member.relation})
                              </span>
                            </div>
                            <div className="col-4 col-sm-3 col-md-3  d-flex justify-content-end">
                              <Link
                                onClick={() => {
                                  myProfileHandler(member);
                                }}
                                to={`/my-profile?member`}
                              >
                                <i className="mhc-icon icon-pencil text-primary fs-13 cursor-pointer me-2"></i>
                              </Link>
                              <button
                                className="me-2"
                                onClick={() => {
                                  deleteMember(member.id);
                                }}
                              >
                                <i className="mhc-icon icon-trash-empty text-primary fs-13 cursor-pointer"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          ) : (
            <LodingComponent className="text-center" />
          )}
          <div className="row align-items-center  mt-3">
            <div className="col-md-5">
              <DashBoardFamilyMember />
            </div>
            <div className="col-md-7 mt-3 mt-md-0">
              <div className="d-flex flex-column flex-sm-row align-items-start align-items-sm-center justify-content-start justify-content-md-end ">
                <div className="fs-7 assistance fw-bolder">
                  Call for Assistance
                </div>
                <CallTOHelp className="ms-0 ms-sm-3 mt-3 mt-sm-0" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : null;
};
export default SwitchProfile;
