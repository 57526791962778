import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import phoneCall from "../../assets/images/phone-call.svg";
import loginUser from "../../assets/images/login-User.png";
import location from "../../assets/images/location-icon.gif";
import { getOTP, requestPin, savePIN, switchPatient, verifyMobileOTP, verifyPIN } from "../../actions/post-call";
import { getHospitalGroupListing, getProfile } from "../../actions/get-calls";

import { setCallTokenReducer, clearCallTokenReducer } from "../../redux/slice/call-token";
import { setSwitchTokenReducer, clearSwitchTokenReducer } from "../../redux/slice/switch-token";
import { setAllUsersReducer, clearAllUsersReducer } from "../../redux/slice/all-users";
import { setSelectedUserReducer, clearSelectedUserReducer } from "../../redux/slice/selected-user";
import { setUserProfileReducer, clearUserProfileReducer } from "../../redux/slice/user-profile";
import { setHospitalGroupReducer, clearHospitalGroupReducer } from "../../redux/slice/group-hospitals";
import { setcurrentLocationReducer, clearcurrentLocationReducer } from "../../redux/slice/current-location";
import { APP_SUB_FOLDER, hospitalAddressList, hospitalgroupName, is_group_exist, sessionTimeOut, showUhidLogin, sitename, topLogin, web_source } from "../../utils/constant";
import { useNavigate } from 'react-router-dom';
import {Logo} from "../../utils/constant";
import { calculateAge } from "../../utils/fliters";
import antiClock from "../../assets/images/anticlock.png";
import { clearMemberProfileReducer } from "../../redux/slice/member-profile";
import { clearSelectedUserCardReducer, setSelectedUserCardReducer } from "../../redux/slice/selected-userCard";
import SpinnerComponent from "../spinnerComponent";
import { addEventListeners, removeEventListeners } from "../../utils/eventListenerUtil";
import OTPInput from "react-otp-input";
const NavBar = () => {

  const [selectedUserProfile, setSelectedUserProfile] = useState(null);
  const [callKey, setCallKey] = useState(null);
  const [switchKey, setSwitchKey] = useState(null);
  const [userList, setUserList] = useState(null);
      
      // hide show
      const[loginHome, setLoginHome]=useState(true);
      const[createPinScreen, setCreatePinScreen]=useState(false);
      const[loginPinScreen, setLoginPinScreen]=useState(false);
      const [pinSetScreen, setPinSetScreen]=useState(false)

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [currentUserId, setCurrentUserId] = useState(null);

  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const [isSubMunu, setIsSubMunu] = useState(true);
  const [isLoginSubMenu, setIsLoginSubMenu] = useState(true);
  const [isAfterLoginSubmenu, setIsAfterLoginSubMenu] = useState(true);
  const [open, setOpen] = useState(false);
  const [showOTPForm, setShowOTPForm] = useState(false);
  const [optErrorMsg, setOptErrorMsg] = useState("");
  const [phone, setPhone] = useState("");
  const [uhid, setUhid]=useState("")
  const [uhidType, setUhidType ]=useState()
  const [isDisabledMob, setIsDisabledMob] = useState(false);
  const [isDisabledUHID, setIsDisabledUHID] = useState(false);
  const [countryCode, setCountryCode] = useState('');
  const [otpM, setOtpM] = useState();
  const [otpMessage, setOtpMessageTag] = useState(false);
  const [otpMessageText, setOtpMessageText] = useState("");
  const [otpRe, setOtpRe] = useState(false);
  const [otpNum, setOtpNum] = useState();
  const [pinNum, setPinNum] = useState('');
  const [pinsetNum, setPinsetNum]=useState("")
  const [isOtpBaseLogin, setIsOtpBaseLogin]=useState(false)
  const [numberEmpty, setNumberEmpty] = useState(false);
  const [uhidEmpty, setUhidEmpty] = useState(false);
  const [currentLocationMob, setCurrentLocationMob]=useState(true);
  const [sendDisbled, setSendDisabled] = useState(false);
  const [spinnerResponse, setSpinnerResponse]=useState(false)
  const [sendOtpResponse, setSendOtpResponse]=useState(false)
  const [spinnerResponseverify, setSpinnerResponseverify]=useState(false)
  const [spinnerResponsesetPin, setSpinnerResponsesetPin]=useState(false)
  const [searchParams, setSearchParams] = useSearchParams();
  const [showNav, setShowNav] = useState(true);

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const callToken = useSelector((state) => state.callToken.value);
  const switchToken = useSelector((state) => state.switchToken.value);
  const allUsers = useSelector((state) => state.allUsers.value);
  const selectedUser = useSelector((state) => state.selectedUser.value);
  const hospitalAddressList = useSelector((state) => state.hospitalAddressList.value);
  const currentLocation = useSelector((state) => state.currentLocation.value);
  const reschedule = useSelector((state) => state.reschedule.value);

  const [selectedMehaID, setSelectedMehaID]=useState(selectedUser)   


  const node = useRef();
  useEffect(() => {
    if (callToken) {
      setCallKey(callToken);
    }
  }, [callToken]);

  useEffect(() => {
    if (switchToken) {
      setSwitchKey(switchToken);
    }
  }, [switchToken]);

  useEffect(() => {
    if (allUsers) {
      setUserList(allUsers);
    }
  }, [allUsers]);

  useEffect(() => {
    if (selectedUser) {
      setSelectedUserProfile(selectedUser);
      setCurrentUserId(selectedUser.id);
    }
  }, [selectedUser]);

  useEffect(() => {
    setTimeout(function() {
        const allProfileList = document.querySelectorAll('input[name="switchUser"]');
        if(searchParams.get("reschedule") === 'true' && reschedule){
          allProfileList.forEach((item) => {
            item.disabled = true;
          });
        }else{
          allProfileList.forEach((item) => {
            item.disabled = false;
          });
        }
    }, 2000);
  }, [reschedule, searchParams]);


  function handlenavbar() {
    setIsNavCollapsed(!isNavCollapsed);
  }

  function handleLogout() {
    //selectedUser
    if (allUsers) {
      dispatch(clearCallTokenReducer());
      dispatch(clearSwitchTokenReducer());
      dispatch(clearAllUsersReducer());
      dispatch(clearSelectedUserReducer());
      dispatch(clearUserProfileReducer());
      dispatch(clearMemberProfileReducer());
      dispatch(clearSelectedUserCardReducer());
      dispatch(clearHospitalGroupReducer());
      localStorage.setItem('alreadyShow','');
      setLoginHome(true)
      setCreatePinScreen(false)
      setLoginPinScreen(false)
      setPinSetScreen(false)
      setPhone("")
      setOtpNum("")
      setPinNum("")
      setPinsetNum("")
      setOtpM("")
      setOtpMessageTag("")
      setOtpMessageText("")
      setOtpRe(false)
      setSpinnerResponse(false)
      setSendOtpResponse(false)
      setSpinnerResponseverify(false)
      setSpinnerResponsesetPin(false)
    }

    localStorage.clear();
    // window.location.reload();
    navigate(`/`);
  }
  
  useEffect(() => {
    const createTimeout1 = () => setTimeout(()=>{ 
      handleLogout()
      //closeNaveBar()
    },1000*sessionTimeOut)

    const listener = () => { 
        clearTimeout(timeout)
        timeout = createTimeout1();      
    } 

    // Initialization
    let timeout =  createTimeout1()
    addEventListeners(listener);

    // Cleanup
    return () => {
      removeEventListeners(listener);
      clearTimeout(timeout);
    }
  },[allUsers])


  function closeNaveBar(e) {
    // e.preventDefault();
    setIsNavCollapsed(true);
    setIsLoginSubMenu(true);
    setIsAfterLoginSubMenu(true);
    setIsSubMunu(true);
  }
  function handlesubmune() {
    setIsSubMunu(!isSubMunu);
    setIsLoginSubMenu(true);
    setIsAfterLoginSubMenu(true);
  }

  function handleLoginSubMenu(e) {
    e.preventDefault();
    setIsLoginSubMenu(!isLoginSubMenu);
    setIsSubMunu(true);
    setIsAfterLoginSubMenu(true);
  }


// / on click Event Function
  const clickOutside = (e) => {
    if(node?.current?.contains(e.target)) {     
      return;
    }       setIsLoginSubMenu(true)
  }
  useEffect(() => { 
    document.addEventListener('mousedown', clickOutside);  
    return () => {
        document.removeEventListener('mousedown', clickOutside);
    }
  }, [])



  function handleAfterLoginSubmenu() {
    setIsAfterLoginSubMenu(!isAfterLoginSubmenu);
    setIsSubMunu(true);
    setIsLoginSubMenu(true);
    setCurrentLocationMob(true)
  }
   const handleCurrentLocation=()=>{
    setCurrentLocationMob(!currentLocationMob)   
    setIsAfterLoginSubMenu(true)
   }

  function handlecollspe() {
    setOpen(!open);
  }
  function mobileNumberCheck(){
    setNumberEmpty(false);
    setUhidEmpty(false)
    setSendDisabled(false);
    setShowOTPForm(false);
  }

  function uhidNumberCheck(){
    setUhidEmpty(false)
    setNumberEmpty(false);
    setSendDisabled(false);
    setShowOTPForm(false);
  }
  function disAbleMobileField(e){
    const uhidValue=e.target.value
    uhidValue == "" ? setIsDisabledMob(false): setIsDisabledMob(true)
    uhidValue !=="" ? setUhidType("uhid"):setUhidType("")
  }
  function disAbleUhidField (e){
    const mobValue=e.target.value
    mobValue  == "" ? setIsDisabledUHID(false): setIsDisabledUHID(true)
    mobValue !=="" ? setUhidType("Mobile_no"):setUhidType("")
  }

  function getMobileCheck() {
    setSendOtpResponse(true)
    // if (showOTPForm) {
    //   document.querySelector("#sendOTP").value = "";
    //   setOptErrorMsg("");
    // }
    if ((phone == "" || phone.length != 10) && isDisabledMob == false) {
      setNumberEmpty(true);
      setUhidEmpty(false);
      setSendOtpResponse(false)
    } else if ((uhid == "" || uhid.length <= 1) && isDisabledUHID == false) {
      setUhidEmpty(true);
      setNumberEmpty(false);
      setSendOtpResponse(false)
    }    else{
      let getValue = uhidType === "uhid" ? uhid : phone;
      requestPin(getValue, uhidType,  countryCode, currentLocation.hospital_group_id, currentLocation.hospitalID)
      .then(function (response) {    
   
        let avialblePin=response.data.is_pin_available
        if(avialblePin===false){
          let getValue = uhidType === "uhid" ? uhid : phone;
          getOTP(getValue, uhidType, currentLocation.hospital_group_id)
          .then(function (login_response) {
          
            if (login_response.status==200){            
              const login_text="The OTP has been sent to the mobile number "
              setLoginHome(false)
              setCreatePinScreen(true)
              setLoginPinScreen(false);
              setOtpMessageTag(true)             
              setOtpMessageText(login_text + login_response.data.mobile_number )
              setIsOtpBaseLogin(false)
              setPinSetScreen(false)
              setSendOtpResponse(false)
              setIsResend(true);
            }
            else{
              setOtpRe(true)      
              setOtpM(login_response.data.errors[0].message)           
              setCreatePinScreen(false)
              setLoginHome(true)
              setLoginPinScreen(false);
              setPinSetScreen(false)
              setSendOtpResponse(false)
            }
          })
        }
        else{
         
          setLoginPinScreen(true);
          setLoginHome(false)
          setCreatePinScreen(false)
          setPinSetScreen(false)
          setSendOtpResponse(false)
        }
    
        
      })
      .catch(function () { });
    }
  }
  const loginVerifyPIN =()=>{
    setSpinnerResponse(true)
    verifyPIN(phone, pinNum,  currentLocation.hospital_group_id, currentLocation.hospitalID)
    .then(function (response) {
    
      let res = response.data;
   
      setSpinnerResponse(false)
      setOptErrorMsg('');
      if(res.errors){
        setOptErrorMsg(res.errors[0].message)
      }
    else{
      setOtpRe(false);
      setOtpMessageTag(false);
      // setCurrentUserId(res.users[0].id);
      setOtpMessageText('');
      dispatch(setCallTokenReducer(response.headers.authorization));
      dispatch(setAllUsersReducer(res.users));
     
      if (res && res.users && res.users.length == 1) {
        dispatch(setSwitchTokenReducer(response.headers.authorization));
        dispatch(setSelectedUserReducer(res.users[0]));
        navigate('/my-dashboard');
       
      }
         else{
          switchUser(res.users[0].id, response.headers.authorization);
          navigate('/my-dashboard');
         }
    }
  
   
    })
    .catch(function () { });
  }


  const getOtpResend=()=>{
    let getValue = uhidType === "uhid" ? uhid : phone;
          getOTP(getValue, uhidType, currentLocation.hospital_group_id)
    .then(function (login_response) {
      const login_text="The OTP has been sent to the mobile number "
      setOtpMessageTag(true)             
      setOtpMessageText(login_text + login_response.data.mobile_number )
      setIsResend(true);
      setTimeLeft(30);
    })
    .catch(function () { });
  }
  const forgotPin=()=>{
    setIsOtpBaseLogin(false)
    getOtpResend()
    setLoginHome(false)
    setCreatePinScreen(true)
    setLoginPinScreen(false);
    setOptErrorMsg('');
  }
  const loginUsingOTP=()=>{
    setIsOtpBaseLogin(true)
    getOtpResend()
    setLoginHome(false)
    setCreatePinScreen(true)
    setLoginPinScreen(false);
    setOptErrorMsg('');
  }
  function verifiyOtp() {
    setSpinnerResponse(true);
    var payload = new FormData();
    payload.append("hospital_group_id", currentLocation?.hospital_group_id);
    payload.append("registration_source", 1);
    payload.append('enterprise_id', currentLocation?.hospital_group_id);
    if (uhidType === "uhid") {
      payload.append('type', 'uhid');
      payload.append('otp', otpNum);
      payload.append('uid', uhid);
    } else {
      payload.append('isd_code', 91);
      payload.append('otp', otpNum);
      payload.append('uid', phone);
    }
    if(isOtpBaseLogin==true){
      setSpinnerResponseverify(true)
      verifyMobileOTP(payload)
        .then(function (response) {
          let res = response.data;
          setSpinnerResponseverify(false)
          setOptErrorMsg('');
          if(res.errors){
            setOptErrorMsg(res.errors[0].message)
          }
           else{
            setOtpMessageTag(false);
            setOtpMessageText('');
    
            dispatch(setCallTokenReducer(response.headers.authorization));
            dispatch(setAllUsersReducer(res.users));
    
            setOtpRe(false);
            // setCurrentUserId(res.users[0].id);
    
            if (res && res.users && res.users.length == 1) {
              dispatch(setSwitchTokenReducer(response.headers.authorization));
              dispatch(setSelectedUserReducer(res.users[0]));
              navigate('/my-dashboard');
             
            }
            else{
              switchUser(res.users[0].id, response.headers.authorization);
              navigate('/my-dashboard');
            }
           }
      
  
        
        })
        .catch(function (err) { });
    }
    else{
      setSpinnerResponseverify(true)
      verifyMobileOTP(payload)
        .then(function (response) {    
          let res = response.data;
          setSpinnerResponseverify(false)
          setOptErrorMsg('');
          if(res.errors){
            setOptErrorMsg(res.errors[0].message)
          }
          if (response.status==200){    
            setLoginPinScreen(false);
            setLoginHome(false)
            setCreatePinScreen(false)
            setPinSetScreen(true)
          }
        })
        .catch(function (err) { });

    }
  }
  const pinSetlogin=()=>{
    setSpinnerResponsesetPin(true)
    savePIN(phone, pinsetNum,  currentLocation.hospital_group_id, currentLocation.hospitalID)
    .then(function (response) {
   
      let res = response.data;
   
      setSpinnerResponsesetPin(false)
      setOptErrorMsg('');
      if(res.errors){
        setOptErrorMsg(res.errors[0].message)
      }
      else{
        setOtpRe(false);
        setOtpMessageTag(false);
        setOtpMessageText('');
        dispatch(setCallTokenReducer(response.headers.authorization));
        dispatch(setAllUsersReducer(res.users));
       
        if (res && res.users && res.users.length == 1) {
          dispatch(setSwitchTokenReducer(response.headers.authorization));
          dispatch(setSelectedUserReducer(res.users[0]));
          navigate('/my-dashboard');
        
        }
        else{
          switchUser(res.users[0].id, response.headers.authorization);
          navigate('/my-dashboard');
        }
      }
     
    
   
    })
    
    .catch(function (err) { });
   }

  function switchUser(id, token) {
    let payload = new FormData();
     payload.append("patient_id", id);
    if(is_group_exist == true){
     payload.append("hospital_group_id", currentLocation?.hospital_group_id);
     payload.append("group_id", currentLocation?.hospital_group_id);
    }

    switchPatient(payload, token ? token : callKey)
      .then(function (switch_response) {
        let switch_token = switch_response.headers.authorization;

        dispatch(setSwitchTokenReducer(switch_token));
        dispatch(setSelectedUserReducer(switch_response.data.user));

        getProfile(switch_token)
          .then(function (profile_response) {
            if (profile_response && profile_response.data) {
              dispatch(setUserProfileReducer(profile_response.data.user));
              // window.location.reload();
            }
          }).catch(function (err) { });

        setShowConfirmation(false);
      }).catch(function (err) { });
  }
  // function switchProfile(e) {
  //   setShowConfirmation(true);
  //   setCurrentUserId(e);
  // }

  function confirmSwitch(e) {
    // console.log(e)
    e.preventDefault();
    switchUser(currentUserId, null);
    setOpen(false);
    dispatch(setSelectedUserCardReducer({"card":"self", "id": currentUserId, mhea_patient_id: selectedMehaID.mhea_patient_id,   name: selectedMehaID.full_name,}));

    // setSelectedUserCardReducer({card: "self",  id: id,  mhea_patient_id: mhea_id, name: name})
  }

  function cancelSwitch() {
    setShowConfirmation(false);
    setOpen(false);
  }


  const [timeLeft, setTimeLeft] = useState(30);
  const [isResend, setIsResend] = useState(false);

  useEffect(() => {
    let timer = setInterval(() => {
      if (isResend) {
        if (timeLeft > 0) {
          setTimeLeft(timeLeft - 1)
        } else {
          clearInterval(timer);
          setIsResend(false);
          setTimeLeft(30);
        }
      }
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  });



  const handleHopital=(e, index)=>{            
    dispatch(setcurrentLocationReducer(hospitalAddressList[index]));
    let path = window.location.pathname; 
    if(path.match('book-consult')){
      window.location.href = `${APP_SUB_FOLDER}/`
    }
    // window.location.pathname == '/user-register' || window.location.pathname == '/qr-register' ? window.location.href = `${APP_SUB_FOLDER}` : window.location.href = `${APP_SUB_FOLDER}/`;
  }

  const pathname = window.location.pathname;
  useEffect(() => {
    if(pathname === `${APP_SUB_FOLDER}/login` || pathname ===  `${APP_SUB_FOLDER}/qr-register`){
      setShowNav(false);
    }else{
      setShowNav(true);
    }
  },[pathname])

  return (
    showNav?
    <nav className="navbar navbar-expand-lg navbar-light bg-white ">
      <div className="container position-relative">
        <Link className="navbar-brand" to="/">
          <img src={`${APP_SUB_FOLDER}${Logo}`} />
        </Link>
        <a href={`tel:${currentLocation?.primary_contact}`}><img src={`${APP_SUB_FOLDER}${phoneCall}`} className="mob-phone-call d-block d-lg-none" /></a>
        <div
          className={`menu-toggle d-lg-none ${isNavCollapsed ? "" : "on"}`}
          onClick={handlenavbar}>
          <div className="line-one"></div>
          <div className="line-two"></div>
          <div className="line-three"></div>
        </div>

        <div className={`${isNavCollapsed ? "" : "mob-submenu"} navbar-collapse`} id="navbarSupportedContent">
          <ul className="navbar-nav align-items-center ms-auto  mb-2 mb-lg-0">
            {(allUsers == null && topLogin == true) ? <>
              <li className="nav-item d-block d-lg-flex  align-items-center clicked order-first order-lg-last">
                <a
                  className="nav-link  d-none d-lg-block"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"  
                  onClick={(e) => handleLoginSubMenu(e)}>
                  <span className="icon-user-_1_ "></span>
                  <span className="ms-1">Login</span>
                  
                </a>
                 <span className=" d-none d-lg-block">|</span>
                  <Link className="nav-link   d-none d-lg-block" to="/user-register">Register</Link>
                <span
                  className="nav-link d-flex d-lg-none justify-content-between align-items-center nav-service"
                  onClick={(e) => handleLoginSubMenu(e)}>
                  Login  {isLoginSubMenu === true ? (<i className="mhc-icon  icon-plus"></i>) : (<i className="mhc-icon  icon-minus"></i>)}
                </span>
                <ul ref={node}  className={`${isLoginSubMenu ? "mobsubnone" : "mobsublock"} dropdown-menu dropdown-right`} aria-labelledby="navbarDropdown">
                  <li>
                    {
                            loginHome===true ?
                  
                    <div className="nav-reigster-form">
                      <div className="d-flex">
                        <div className="w-25">
                          <select
                            className="form-select"
                            value={countryCode}
                            onChange={(e) => setCountryCode(e.target.value)}
                            disabled={isDisabledMob}
                          >
                            <option value="91">+91</option>
                          </select>
                        </div>
                        <div className="w-75">
                          <div className={`input-group enter-otp ${sendDisbled? "hide_send_btn":""}`}>
                            <input
                              type="text"
                              maxLength="10"
                              className="form-control border-start-0 border-rasius "
                              id="exampleFormControlInput1"
                              placeholder="Mobile Number"
                              onChange={(e) => {
                                disAbleUhidField(e)
                                setPhone(e.target.value);
                              }}
                              onKeyDown={(e) => mobileNumberCheck()}
                              onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }}
                              disabled={isDisabledMob}
                            />
                        {/* <button
                              type="submit"
                              className="btn-custom px-3 border-0"
                              id="basic-addon1"
                              onClick={() => getOTPMobile()}
                        
                              >
                           Send OTP
                              </button> */}
                          </div>
                       
                        </div>
                       
                      </div>
                      {numberEmpty === true && (<><p className="m-0 fs-7 text-primary mt-2">Please enter a valid {phone.length > 0 ? "Mobile":"mobile/UHID"} number.</p></>)}
                     {
                        showUhidLogin===true ?<>  <div className="text-center my-2 d-flex justify-content-center ">
                        <p className="text-primary mb-0 or-circle"><b>OR</b></p>
                      </div>
                      <div className={`input-group enter-otp ${sendDisbled? "hide_send_btn":""}`}>
                            <input
                              type="text"
                              maxLength="25"
                              className="form-control border-radius-left-10 "
                              id="exampleFormControlInput1"
                              placeholder="UHID"
                              disabled={isDisabledUHID}                       
                            
                              onKeyDown={(e) => uhidNumberCheck()}               
                              onChange={(e) => {
                                disAbleMobileField(e)
                                setUhid(e.target.value);
                              }}  
                            />
                            </div>
                            {uhidEmpty === true && (<><p className="m-0 fs-7 text-primary mt-2"> Please enter a valid UHID number.</p></>)}
                            </>
                      :""
                     }
                     
                   
                            <div className="text-center mt-3">
                            {
                                showOTPForm===true ?  <button disabled className="btn-custom px-3 py-2 border-0 disabled  rounded-1">  Send OTP </button>
                              :  <button
                              type="submit"
                              className="btn-custom px-3 py-2 border-0"
                              id="basic-addon1"
                              onClick={() => getMobileCheck()}
                              style={{borderRadius:"0.25rem"}}

                              >
                            Submit {sendOtpResponse == true? <SpinnerComponent />: null}
                            </button>
                            }
                          
                            </div>
                            <p className="mb-0 mt-2">
                  Please use mobile number registered with{" "}
                  {sitename} to avoid re-registration
                </p>
                    
                            {otpRe === true && (
                  <>
                    {/* <p className="m-0 fs-7 text-primary mt-2">{otpM}</p> */}
                    <p className="m-0 fs-7 text-primary mt-2 mb-3">
                      Sorry, this {uhidType === "uhid" ? "UHID" : "number"} is
                      not registered with us. Please register to continue
                    </p>
                  </>
                )}
                      
              

                      <p className="mt-3 mb-0">
                        <Link to="/user-register" style={{textDecoration: "underline"}} onClick={(e) => closeNaveBar(e)} className="text-primary">Register Now</Link> on the {sitename} Patient Portal
                      </p>
                        </div>
                     :"" }

{
              loginPinScreen  == true ?<div className="mhc-loginscreen h-auto">
              
                   
             
                <div class="nav-reigster-form text-center ">
            
                  <p className="mt-4 text-secondary">Please enter your 4 digit PIN</p>
                <OTPInput
                    value={pinNum}
                    onChange={setPinNum}
                    numInputs={4}  
                    containerStyle="otp-wrapper"   
                    inputStyle="input"            
                    // renderSeparator={<span>-</span>}
                    renderInput={(props) => <input {...props} />}
                     />
                      <button className="btn btn-custom rounded-1 mt-3 px-5" onClick={()=>loginVerifyPIN()} >Verify Pin {spinnerResponse == true? <SpinnerComponent />: null}   </button>
                      {optErrorMsg !=''? <p className="text-primary mt-2">{optErrorMsg}</p> : ''}
                      <div className="d-flex mt-4">
                        <div><button className="btn text-primary text-decoration-none me-5" onClick={()=>forgotPin()} >Forgot Pin ?  </button></div>
                        <div><button className="btn text-primary text-decoration-none ms-5" onClick={()=>loginUsingOTP()} >  Login Using OTP</button></div>
                      </div>
                      </div>
                </div>:null
              }
       
            {
              createPinScreen == true  ?     <div className="mhc-loginscreen h-auto">
                    <div class="nav-reigster-form text-center ">
              
                
                {otpMessage === true && (<p className="m-0 fs-7 mt-4">{otpMessageText}</p>)}
                 
                  <OTPInput
                    value={otpNum}
                    onChange={setOtpNum}
                    numInputs={4}  
                    containerStyle="otp-wrapper"   
                    inputStyle="input"            
                    // renderSeparator={<span>-</span>}
                    renderInput={(props) => <input {...props} />}
                     />
                                   {optErrorMsg !=''? <p className="text-primary mt-2">{optErrorMsg}</p> : ''}
                     <div className="d-flex justify-content-center align-items-center">
                       <button className="btn btn-custom rounded-1 mt-3" onClick={()=>verifiyOtp()} >Verify OTP {spinnerResponseverify == true? <SpinnerComponent />: null}    </button>
                       
                       {
                        isResend ? (
                          <div className="anitClock ms-4 mt-3">
                            <img src={`${APP_SUB_FOLDER}${antiClock}`} className="anti-img" alt="" />
                            <p className="otp-time">{timeLeft}</p>
                          </div>
                        ) : (
                          <button  className="btn fs-6 text-decoration-none text-primary text-nowrap mt-3 ms-4" id="basic-addon1" onClick={()=>getOtpResend()} >Resend OTP</button>
                        )
                      }
                    </div>
                </div>
              </div>:null
            }
          {
            pinSetScreen===true? <div className="mhc-loginscreen h-auto">
            <div class="nav-reigster-form text-center ">   
        
            <p className="mt-4 mb-0 text-secondary">Please set your 4 digit PIN</p>
         
          <OTPInput
            value={pinsetNum}
            onChange={setPinsetNum}
            numInputs={4}  
            containerStyle="otp-wrapper"   
            inputStyle="input"            
            // renderSeparator={<span>-</span>}
            renderInput={(props) => <input {...props} />}
             />
               <button className="btn btn-custom rounded-1 mt-3" onClick={()=>pinSetlogin()}  >Set PIN   {spinnerResponsesetPin == true? <SpinnerComponent />: null}   </button>
            
        </div>
      </div>:null
          }
                  </li>
                </ul>
              </li>
              <li> <Link onClick={(e) => closeNaveBar(e)} className="nav-link dropdown-toggle position-relative  d-block d-lg-none" to="/user-register">Register</Link></li>
              </>
             : ''
            }


            
                <li className="nav-item dropdown ">
                  
                <a
                  className={`${hospitalAddressList?.length == 1 ? "":"dropdown-toggle" } active   nav-link d-none d-lg-block 11`}
                  href="#"
                  onClick={(e) => e.preventDefault()}
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  
                      <img src={`${APP_SUB_FOLDER}${location}`} className="loginUser1 me-1" />
                     {currentLocation?.label} 
                 
                   
                </a>
                <span className="nav-link d-flex d-lg-none justify-content-between align-items-center nav-service" onClick={handleCurrentLocation}>
                {currentLocation?.label} {currentLocationMob === true ? (<i className="mhc-icon icon-plus"></i>) : (<i className="mhc-icon icon-minus"></i>)}
                  </span>
                <ul
                  className={`${hospitalAddressList?.length == 1 ? "d-none":"" } ${currentLocationMob ? "mobsubnone" : "mobsublock"} dropdown-menu dropdown-right locationDrop`}
                  aria-labelledby="navbarDropdown"
                >
                <li>
                <div className="px-2 pt-3 " style={{"minWidth":"300px"}}>
                  {
                    hospitalAddressList?.map((item,index)=>{    
                            
                      return(
                        <div key={index} className="form-check mt-0 custom-checkbox mb-3"  >
                        <input className="form-check-input" type="radio"  name="hopistalLocation" defaultValue={item.hospitalID} data-type={item.type} title={item.label}  id={`hopistal${item.hospitalID}`} onChange={(e)=>handleHopital(e, index)} checked={item.hospitalID===currentLocation?.hospitalID ? true:false} />
                        
                        <label className="form-check-label custom-text cursor-pointer d-inline fw-bolder" htmlFor={`hopistal${item.hospitalID}`} >
                        {item.label}<br />
                        <small className="fw-normal">{item.address}</small>
                        </label>
                      </div>
                      )
                    })
                  }
              
           
                    </div>
                </li>
                </ul>
              </li>
                 <li className="navbar-border"></li>
        
              {allUsers? 
                <>
              <li className="nav-item dropdown order-first order-lg-last">
                <a
                  className="nav-link dropdown-toggle d-none d-lg-block"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  onClick={(e) => handleLoginSubMenu(e)}
                  aria-expanded="false">
                  <img src={selectedUserProfile?selectedUserProfile.profile_image:loginUser} className="loginUser" />
                
                  <span className="ms-1">{selectedUserProfile && selectedUserProfile.full_name}</span>
                </a>
                <span className="nav-link d-flex d-lg-none justify-content-between align-items-center nav-service" onClick={handleAfterLoginSubmenu}>
                  {selectedUserProfile && selectedUserProfile.full_name} {isAfterLoginSubmenu === true ? (<i className="mhc-icon icon-plus"></i>) : (<i className="mhc-icon icon-minus"></i>)}
                </span>

                
                <ul ref={node} className={`${isAfterLoginSubmenu ? "mobsubnone" : "mobsublock"} dropdown-menu dropdown-right`} aria-labelledby="navbarDropdown">
                   {allUsers.length > 1?
                   <>
                    <li className="dropdown-item pb-2  cursor-pointer border-bottom" onClick={handlecollspe} aria-expanded={open} >
                      <span className="mhc-icon icon-exchange me-2"></span> Switch
                      Profile
                    </li>
                    <li className="p-0">
                      <div className={`collapse collapse-switch ${open === true && "show"}`} >
                        <ul className="list-unstyled">
                          {
                            userList && userList.map((user_item) => {
                              return (
                                <li className="dropdown-item py-2 border-bottom" key={user_item.id}>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="switchUser"
                                      id={'navUserId_'+user_item.id}
                                      value={user_item.id}
                                      checked={'navUserId_'+currentUserId === 'navUserId_'+user_item.id}
                                      onChange={(e) => { setCurrentUserId(e.target.value); setShowConfirmation(true); setSelectedMehaID(user_item)} }
                                    />
                                    <label className="form-check-label ms-2" htmlFor={'navUserId_'+user_item.id} >
                                      {user_item.full_name}
                                      <br />
                                      <small>{user_item.gender}, {calculateAge(user_item.dob)}</small>
                                    </label>
                                  </div>
                                </li>
                              )
                            })
                          }
                        </ul>
                      </div>
                    </li>

                      {
                        showConfirmation && (
                          <li className="dropdown-item py-2 border-bottom">
                            <div className="profilebtn">
                              <span type="button" onClick={cancelSwitch}
                                className="btn btn-outline btn-outline-secondary rounded-1 px-2 py-0">
                                Cancel
                              </span>
                              <button type="button" onClick={(e) => confirmSwitch(e)} className="btn btn-outline btn-primary py-0 px-2 rounded-1 ms-3">Continue</button>
                            </div>
                          </li>
                        )
                      }
                    </>
                    : null}
                  <li className="dropdown-item py-2 border-bottom">
                    <Link onClick={(e) => closeNaveBar(e)} to="/my-dashboard"><span className="mhc-icon icon-dashboard me-2"></span>Dashboard</Link>
                  </li>
                  <li className="dropdown-item py-2 border-bottom">
                    <Link onClick={(e) => closeNaveBar(e)} to="/my-profile"><span className="icon-user-_1_ me-2"></span>My Profile</Link>
                  </li>
                  <li className="dropdown-item pt-2">
                    <a className="cursor-pointer" 
                      onClick={((e) => closeNaveBar(e), handleLogout)}>
                      <span className="mhc-icon icon-log-out me-2"></span>
                      Logout
                    </a>
                  </li>
                </ul>
                </li>
                </>
                :null  
            }
          </ul>
        </div>
      </div>
      <ToastContainer />
    </nav>
    : null
  );
};

export default NavBar;