import React, { useEffect, useState } from "react";
import Breadcrumb from "../../component/breadcrumb/index";
import {
  getSpecialitiesDectorSearch,
  specialitiesListByHospital,
} from "../../actions/get-calls";
import {
  apitoken,
  APP_SUB_FOLDER,
  DefaultMetaTag,
  groupId,
  hospitalgroupId,
} from "../../utils/constant";
import { useSelector, useDispatch } from "react-redux";
import SearchIcon from "../../assets/images/mhc-search-icon.svg";
import { Link, useLocation, useParams } from "react-router-dom";
import Doctors from "../consult-doctor/doctors";
import LodingComponent from "../../component/loding";
import DashboardLink from "../../component/dashboard-link";
import Helmet_SEO_META from "../../component/seo_meta";
import HospitalListModal from "../../component/hospital-list-modal";

function BookingConsult() {
  const location = useLocation();
  const { specialities, Id, sId } = useParams();
  const locationData = useSelector((state) => state.location.value);
  const [specialitiesList, setSpecialitiesList] = useState([]);
  const [specialitiesInit, setSpecialitiesIniti] = useState([]);
  const [doctorsList, setDoctorsList] = useState([]);
  const [serchText, setSerchText] = useState("");
  const [dataNotFond, setDataNotFound] = useState(false);
  const [consultType, setConsultType] = useState("0");

  const currentLocation = useSelector((state) => state.currentLocation.value);
  useEffect(() => {
    if (currentLocation) {
      specialitiesListByHospital(
        currentLocation?.hospitalID,
        currentLocation.hospital_group_id,
        apitoken
      )
        .then(function (response) {
          // console.log("response>>", response)
          let res = response.data;
          setSpecialitiesList(res.specialities);
          setSpecialitiesIniti(res.specialities);
        })
        .catch(function (err) {});
    }
  }, [currentLocation]);

  const handledSearchData = (e) => {
    setSerchText(e.target.value);
    setSpecialitiesList([]);
    setDoctorsList([]);
    if (e.target.value != "" && e.target.value.length > 2) {
      let payload = `?hospital_id=${currentLocation.hospitalID}&group_id=${currentLocation.hospital_group_id}&hospital_group_id=${currentLocation.hospital_group_id}&keyword=${e.target.value}`;
      getSpecialitiesDectorSearch(payload)
        .then(function (response) {
          if (
            response.data.specialities.length > 0 ||
            response.data.doctors.length > 0
          ) {
            setDataNotFound(false);
            let specialities = response.data.specialities;
            let doctors = response.data.doctors;
            setSpecialitiesList(specialities);
            setDoctorsList(doctors);
          } else {
            setDataNotFound(true);
          }
        })
        .catch(function (err) {});
    }
    if (e.target.value.length <= 2) {
      setDataNotFound(false);
      setSpecialitiesList(specialitiesInit);
      setDoctorsList([]);
    }
  };
  const breadcrumArrry = [
    {
      name: "Book Consult",
      url: "",
    },
  ];
  const pageURL = window.location.origin + window.location.pathname;
  const [SEO_META, setSEO_META] = useState({
    title: DefaultMetaTag.title,
    description: DefaultMetaTag.description,
    keywords: DefaultMetaTag.keyword,
    pageURL: pageURL,
  });

  return (
    <React.Fragment>
      <Helmet_SEO_META SEO_META={SEO_META} />
      <section>
        <div className="min-body-height container">
          <div className="row align-items-center">
            <div className="col-md-9">
              <Breadcrumb textUrl={breadcrumArrry} />{" "}
            </div>
            <div className="col-md-3 d-none d-md-block">
              <DashboardLink />
            </div>
          </div>
          <div className="row justify-content-between">
            <div className="col-lg-6 ">
              <h1 className="fs-4 mb-1">Book Consult <HospitalListModal isOpen={true}/></h1>
              <p className="m-0 fs-6">
                Consult with top doctors across specialities
              </p>
            </div>
            <div className="col-lg-5 mt-4 mt-lg-0">
              <div className="Mhc-search w-100">
                <div className="input-group ">
                  <span className="input-group-text">
                    <img
                      src={`${APP_SUB_FOLDER}${SearchIcon}`}
                      alt="Search-icon"
                    />
                  </span>
                  <input
                    type="text"
                    onKeyUp={(e) => handledSearchData(e)}
                    className="form-control"
                    placeholder="Search Specialities, Doctors"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mhc-tabs my-4">
            {/* <Specialities /> */}
            <div className="row sakra-specialitiesList">
              {specialitiesList?.length == 0 && doctorsList?.length == 0 ? (
                <div className="col-lg-12">
                  <div className="mhc-Loding text-center my-5">
                    {/* <img src="../loader.gif" alt="loader" /> */}
                    {dataNotFond == true ? (
                      <h5 className="text-center mt-5">
                        No result found for your search - {serchText}
                      </h5>
                    ) : (
                      <LodingComponent className="mhc-Loding-minHeight" />
                    )}
                  </div>
                </div>
              ) : (
                <>
                  {doctorsList?.length > 0 && specialitiesList?.length > 0 ? (
                    <h5>Specialities</h5>
                  ) : null}

                  {specialitiesList?.map((item) => {
                    return (
                      <div className="col-md-6 col-lg-4" key={item.id}>
                        <Link
                          to={`/book-consult/${item.slug}/${currentLocation.hospitalID}/${item.id}`}
                        >
                          <div className="specialitiesList-card ">
                            <img src={item.icon} alt={item.name} />
                            {item.name}

                            <span className="mhc-icon icon-next"></span>
                          </div>
                        </Link>
                      </div>
                    );
                  })}

                  {doctorsList?.length > 0 ? (
                    <>
                      {specialitiesList?.length > 0 ? (
                        <h5 className="mt-3">
                          {" "}
                          {doctorsList.length == 1 ? "Doctor" : "Doctors"}
                        </h5>
                      ) : null}

                      <div className="mhc-consult-type d-flex bd-highlight flex-column flex-lg-row align-items-start  align-items-lg-center position-relative mb-4">
                        <div className="order-0 order-lg-0">
                          <span className="fw-bold ">Consult Type</span>
                        </div>
                        <div className="order-2 order-lg-0">
                          <ul className="list-unstyled mb-0 mt-4 mt-lg-0 d-flex align-items-center">
                            <li>
                              <div className="form-check mt-1 ms-0 ms-lg-4">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="All"
                                  value="0"
                                  checked={consultType === "0"}
                                  onChange={(e) =>
                                    setConsultType(e.target.value)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="All"
                                >
                                  All
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="form-check mt-1 ms-3 ms-sm-4">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="Hospital"
                                  value="2"
                                  checked={consultType === "2"}
                                  onChange={(e) =>
                                    setConsultType(e.target.value)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="Hospital"
                                >
                                  At Hospital/ Clinic
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="form-check mt-1 ms-3 ms-sm-4">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="Virtual"
                                  value="1"
                                  checked={consultType === "1"}
                                  onChange={(e) =>
                                    setConsultType(e.target.value)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="Virtual"
                                >
                                  Virtual
                                </label>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <Doctors
                        doctors={doctorsList}
                        specialities={specialities}
                        consultType={consultType}
                        sId={0}
                        Id={currentLocation.hospitalID}
                      />
                    </>
                  ) : null}
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default BookingConsult;
