import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  Navigate,
} from "react-router-dom";
import NavBar from "./component/navbar/index";
import Footer from "./component/footer/index";
import BookingConsult from "./pages/booking/index";
import UserRegister from "./pages/register/index";
import ProfileEdit from "./pages/my-profile/profile-edit";
import ConsultDoctor from "./pages/consult-doctor/specialities-doctors";
import DectorProfile from "./pages/dector-profile/dectorProfile";
import PatientDashBoard from "./pages/patient-portal/patient-dashboard";
import PatientMyBooking from "./pages/patient-portal/patientMyBooking";
import PatientMyprescriptions from "./pages/patient-portal/patientMyprescriptions";
import PatientMyDocument from "./pages/patient-portal/patientMyDocument";
import PatientMyReport from "./pages/patient-portal/patientMyReport";
import DashboardLogin from "./component/login-register/dashboardLogin";
import DashboardLogin2 from "./component/login-register/dashboardLogin2";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { locationReducer } from "./redux/slice/location-slice";
import PageNotFound from "./component/404";
import PatientMyBills from "./pages/patient-portal/patientMyBills";
import MyDischargeSummary from "./pages/patient-portal/My-Discharge-Summary";
import OrderMedicine from "./pages/order-medicine/OrderMedicine.jsx";
import { apiPATH, APP_SUB_FOLDER, site_SEO_META, hospitalgroupName } from "./utils/constant";
import QrRegister from "./pages/register/qr-rigster";
import ReactGA from "react-ga4";
import BookPackageOrTest from "./pages/book-package-or-test";
import PackageComparison from "./pages/package-comparison/packageComparison";
import PackageBooking from "./pages/book-package-or-test/package/packageBooking";
import PackageBookingConfirmed from "./pages/Booking-Confirmed/packageBookingConfirmed";
import PackageTestDetail from "./pages/book-package-or-test/packageTestEyeButton";
import TestBooking from "./pages/book-package-or-test/test/testBooking";
import CarePackageCategories from "./pages/care-package/carePackageCategories";
import CarePackages from "./pages/care-package/carePackages";
import CareBookingConfirmed from "./pages/care-package/careBookingConfirmed";
import CarePackageEyeButton from "./pages/care-package/CarePackageEyeButton";
import CarePackageBooking from "./pages/care-package/carePackageBooking";
import HomecareService from "./pages/homecareService/homecareService";
import HomecareCategory from "./pages/homecareService/homecareCategory";
import HomecareCategoryPackages from "./pages/homecareService/homecareCategoryCompare";
import HomecarePackageCompare from "./pages/homecareService/homecarePackageCompare";

import { setHospitalGroupReducer, clearHospitalGroupReducer } from "./redux/slice/group-hospitals";
import { setcurrentLocationReducer, clearcurrentLocationReducer } from "./redux/slice/current-location";
import HealthCheckUp from "./pages/health-check-up/HealthCheckUp";
import HealthCheckUpComparison from "./pages/health-check-up/healthCheckUpComparison";
import HealthCheckupBooking from "./pages/health-check-up/healthCheckupBooking";
import RadiologyComparison from "./pages/radiology-test/radiologycomparison";
import Radiologybooking from "./pages/radiology-test/radiologybooking";


function App() {
  const location = useSelector((state) => state.location.value);
  const dispatch = useDispatch();
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [status, setStatus] = useState(null);
  const allUsers = useSelector((state) => state.allUsers.value);

  const hospitalAddressList = useSelector((state) => state.hospitalAddressList.value);


  if (site_SEO_META == true) {
    // OUR_TRACKING_ID
    ReactGA.initialize([
      { trackingId: "UA-155852831-1", gaOptions: { name: "Google" } },
    ]);
    // ReactGA.send("pageview");
  }

  useEffect(() => {

    if (!hospitalAddressList || hospitalAddressList[0].hospital_group_code != hospitalgroupName) {


      let getData = {
        "hospitals": [
          {
            "hospitalID": 5,
            "name": "Woodlands Multispeciality Hospital Ltd",
            "display_name": "Woodlands Multispeciality Hospital Ltd",
            "images": "https:\/\/parasimages.myhealthcare.life\/paraslive\/Assets\/1\/S0tMzszJLKn0zE1MTzU0MjAyNjA2tDA0MzE1MTI3NwMA.jpeg?v=1157539412",
            "address": "8/5, Alipore Road, Kolkata - 700 027",
            "primary_contact": "7604075551",
            "email": "enquiry@woodlandshospital.in",
            "type": "hospital",
            "label": "Woodlands Multispeciality Hospital",
            "slug": "woodlands-multispeciality-hospital",
            "hospital_group_id": 4,
            "hospital_group_code": "woodlands"
          }
        ]
      }
      // console.log(getData.hospitals)
      dispatch(setHospitalGroupReducer(getData.hospitals));
      dispatch(setcurrentLocationReducer(getData.hospitals[0]));


      // fetch(`${apiPATH}mhea-hospital-list?hospital_group_code=${hospitalgroupName}`).then((response) => response.json())
      // .then((data) => {
      //   console.log(data);

      //     let getData = [...data.hospitals].sort((a, b) => a.hospitalID - b.hospitalID);
      //     dispatch(setHospitalGroupReducer(getData));
      //     dispatch(setcurrentLocationReducer(getData[0]));
      //     localStorage.clear();
      // })
      // .catch((err) => {
      //   console.log(err.message);
      // });
    }
  }, [hospitalAddressList]);
     
  return (
    <React.Fragment>
      <Router basename={`${APP_SUB_FOLDER}`}>
      
        {allUsers?.length> 0  ?<NavBar /> :window.location.pathname === '/login' || window.location.pathname === '/qr-register' ? null: <NavBar />}
        <Routes>
          <Route path={`/`} element={<BookingConsult />} />
          <Route path={`/book-consult/:specialities/:Id/:sId`} element={<ConsultDoctor />} />
          <Route path={`/book-consult/:specialities/:doctor/:hId/:sId/:dId/:cId`} element={<DectorProfile />} />

          <Route path={`/login`} element={<DashboardLogin2 />} />
          <Route path={`/user-register`} element={<UserRegister />} />
          <Route path={`/qr-register`} element={<QrRegister />} />
          <Route path={`/my-dashboard`} element={<PatientDashBoard />} />
          <Route path={`/my-profile`} element={<ProfileEdit />} />
          <Route path={`/my-bookings`} element={<PatientMyBooking />} />
          <Route
            path={`/my-prescriptions`}
            element={<PatientMyprescriptions />}
          />
          <Route path={`/my-documents`} element={<PatientMyDocument />} />
          <Route path={`/my-reports`} element={<PatientMyReport />} />
          <Route path={`/my-bills`} element={<PatientMyBills />} />
          <Route path={`/my-discharge-summary`} element={<MyDischargeSummary />} />
          <Route path={`/order-medicine`} element={<OrderMedicine />} />
          <Route path={`/health-checkup`} element={<HealthCheckUp />} />
          <Route path={`/health-checkup/package-comparison/:categoryName/:compare1/:compare2`} element={<HealthCheckUpComparison />} />
          <Route path={`/health-checkup/:categoryName/:id`} element={<HealthCheckupBooking />} /> 

         
         
          <Route path={`/radiology-test/package-comparison/:categoryName/:compare1/:compare2`} element={<RadiologyComparison />} />
          <Route path={`/radiology-test/:categoryName/:id`} element={<Radiologybooking />} /> 
         
          <Route
            path={`/book-package-or-test/packages`}
            element={<BookPackageOrTest />}
          />
          <Route
            path={`/book-package-or-test/test`}
            element={<BookPackageOrTest />}
          />
          <Route
            path={`/package-comparison/:categoryName/:compare1/:compare2`}
            element={<PackageComparison />}
          />
          <Route
            path={`/book-package-or-test/packages/:categoryName/:id`}
            element={<PackageBooking />}
          />
          <Route path={`/package-booking`} element={<PackageBooking />} />
          <Route
            path={`/package-booking/:slug/:dId`}
            element={<PackageTestDetail />}
          />
          <Route
            path={`/book-package-or-test/test/booking`}
            element={<TestBooking />}
          />
          <Route
            path={`/package-booking-confirm`}
            element={<PackageBookingConfirmed />}
          />

          {/* home care service */}
          <Route path={`/homecare-services`} element={<HomecareService />} />
          <Route
            path={`/homecare-service-category/:categoryName/:categoryId`}
            element={<HomecareCategory />}
          />
          <Route
            path={`/homecare-services/:slug/:categoryId`}
            element={<HomecareCategory />}
          />

          <Route
            path={`/homecare-service-packages/:slug/:hospitalSlug/:categoryId/:hospitalId`}
            element={<HomecareCategoryPackages />}
          />
          <Route
            path={`/homecare-service-package-compare/:hospitalId/:compare1/:compare2/:categoryName/:subCatName`}
            element={<HomecarePackageCompare />}
          />
          {/* <Route path={`/homecare-service-booking/:categoryName/:subCatName/:categoryId/:hospitalId`} element={<HomecareServiceBooking />} /> */}

          {/* care  package */}
          <Route
            path={`/care-package-categories`}
            element={<CarePackageCategories />}
          />
          <Route
            path={`/care-packages/:categoryName/:categoryId`}
            element={<CarePackages />}
          />
          <Route
            path={`/care-package-comparison/:categoryName/:compare1/:compare2`}
            element={<PackageComparison />}
          />
          <Route
            path={`/care-package-booking/:categoryName/:subCategoryName`}
            element={<CarePackageBooking />}
          />
          <Route
            path={`/care-booking-confirmed`}
            element={<CareBookingConfirmed />}
          />
          <Route
            path={`/care-package-info`}
            element={<CarePackageEyeButton />}
          />

          <Route path={`*`} element={<PageNotFound />} />
        </Routes>
        <Footer />
      </Router>
    </React.Fragment>
  );
}

export default App;
