import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Tabs, Tab, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from "react-datepicker";
import Slider from "react-slick";
import antiClock from "../../assets/images/anticlock.png";
import { getProfile, getProfileWithPayload, reasonBookingCall } from "../../actions/get-calls";
import { switchPatient, bookAppointmentHospital, getOTPWithPayloadTokenBased, addFamilyMember, verifyMobileOTP, verifyMobileOTPTokenBased, updateFamilyMember } from "../../actions/post-call";
import countries from "../../assets/data/countries.json";

import { setSwitchTokenReducer } from "../../redux/slice/switch-token";
import { setSelectedUserReducer } from "../../redux/slice/selected-user";
import { setUserProfileReducer } from "../../redux/slice/user-profile";
import { setRescheduleReducer } from "../../redux/slice/reschedule";
import DashBoardFamilyMember from "./dashboardFamilyMember";
import { Link } from "react-router-dom";
import { calculateAge, formatAMPM } from "../../utils/fliters";


import SpinnerComponent from "../spinnerComponent";
import { APP_SUB_FOLDER, is_group_exist, web_source } from "../../utils/constant";
import LodingComponent from "../loding";


const diaTabs = { FamilyMemberY: "Yes", FamilyMemberN: "No", };


const genderList = ["Male", "Female", "Other"];

const 
FamilyMember = ({

  consultT,
  timeSlot,
  docId,
  hospitalId,
  setProfileSelect,
  setMemberSelect,
  setBookSuccess,
  setBookAppointRes,
  setBookSuccessTime,
  resetBooking,
  reasonCode, btnDisplay
}) => {


  const [currentUserId, setCurrentUserId] = useState(null);
  const [currentMemberId, setCurrentMemberId] = useState(null);

  const [addToken, setAddToken] = useState(null);
  const [patientId, setPatientId] = useState(null);
  const [currentFamilyMembers, setCurrentFamilyMembers] = useState(null);

  const [callToken, setCallToken] = useState(null);
  const [switchToken, setSwitchToken] = useState(null);
  const [loggedUsers, setLoggedUsers] = useState(null);

  const [selectedLocalUser, setSelectedLocalUser] = useState(null);

  const dispatch = useDispatch();
  const callKey = useSelector((state) => state.callToken.value);
  const switchKey = useSelector((state) => state.switchToken.value);
  const allUsers = useSelector((state) => state.allUsers.value);
  const selectedUser = useSelector((state) => state.selectedUser.value);
  const reschedule = useSelector((state) => state.reschedule.value);
  const [rescheduleUser, setRescheduleUser] = useState(null);
  const [reasonCodeModal, setReasonCodeModal]=useState(false)
  const [reasonVal, setReasonVal] = useState(null);
  const [reasonList, setReasonList]=useState();
  const [reasonStatus, setReasonStatus]=useState(false);
  
  const [spinnerResponse, setSpinnerResponse]=useState(false)
  const currentLocation = useSelector((state) => state.currentLocation.value);
 

  useEffect(() => {
    if (callKey) {
      setCallToken(callKey);
    }
  }, [callKey]);

  useEffect(() => {
    if (switchKey) {
      setSwitchToken(switchKey);
    }
  }, [switchKey]);

  useEffect(() => {
    if (allUsers) {
      setCurrentUserId(allUsers[0].id);
      setCurrentMemberId(allUsers[0].id);
      setLoggedUsers(allUsers);
    }
  }, [allUsers]);

  useEffect(() => {
    if (selectedUser) {
      setSelectedLocalUser(selectedUser);
      setCurrentUserId(selectedUser.id);
      setCurrentMemberId(selectedUser.id);
      setMemberSelect(selectedUser);
      setCurrentFamilyMembers(selectedUser.dependents);
      resetBooking();
    }
  }, [selectedUser]);


  useEffect(() => {
    if(reschedule){
      setRescheduleUser(reschedule.patient_prescription.patient_id);
      setCurrentMemberId(reschedule.patient_prescription.patient_id)

      const rescheduleID = selectedUser.dependents.find(obj => {
        return obj.id === reschedule.patient_prescription.patient_id;
      });
      
      if(rescheduleID){
        setMemberSelect(rescheduleID);
      }else{
        setMemberSelect(selectedUser);
      }
      setReasonVal(0)

    }
  },[reschedule]);

  const [modal, setModal] = useState(false);
  const [familyTab, setFamilyTab] = useState(diaTabs.FamilyMemberY);
  const [error, setError] = useState();
  const [errorflag, setErrorFlag] = useState(false);

  const [profile, setProfile] = useState(null);
  const [DOB, setDOB] = useState(new Date());
  const [allState, setAllState] = useState(null);
  const [allCity, setAllCity] = useState(null);

  const profileHandle = (e) => {
    const file = e.target.files[0];
    setProfile(file);
  };

  const [showForm, setShowForm] = useState('search');
  const [isUpdate, setIsUpdate] = useState(false);

  const [searchText, setSearchText] = useState('');
  const [currentOTP, setCurrentOTP] = useState('');
  const [searchedUserList, setSearchedUserList] = useState([]);

  function handleShow() { setModal(true); }


  function handleClose() {
    setModal(false);
    setShowForm('search');

    setFamilyTab(diaTabs.FamilyMemberY);
    setSearchText('');
    setCurrentOTP('');

    setAddToken(null);
    setPatientId(null);

    setTimeLeft(30);
    resetForm();

    setIsUpdate(false);
    setIsResend(false);

    setSearchedUserList([]);
    setDOB(new Date());
  }

  function handletabs(e) {
    setFamilyTab(e.target.value);
    if (e.target.value === diaTabs.FamilyMemberN) {
      setShowForm('manage');
      setSearchText('');
    } else {
      setShowForm('search');
    }
    resetForm();
  }

  function resetForm() {
    formik.setFieldValue('family_member_id', 0);
    formik.setFieldValue('firstName', '');
    formik.setFieldValue('lastName', '');
    formik.setFieldValue('mobile', '');
    formik.setFieldValue('email', '');
    formik.setFieldValue('addressOne', '');
    formik.setFieldValue('addressTwo', '');
    formik.setFieldValue('pincode', '');
    formik.setFieldValue('gender', "0");
    formik.setFieldValue('marital_status', "Single");
    formik.setFieldValue('dob', new Date());
    formik.setFieldValue('relation', "Father");
    formik.setFieldValue('country', "India");
  }

  function selectSwitch(id) {
    setCurrentUserId(id);
    setCurrentMemberId(id);

    let payload = new FormData();
    payload.append("patient_id", id);
    if(is_group_exist == true){
      payload.append("hospital_group_id", currentLocation?.hospital_group_id);
      payload.append("group_id", currentLocation?.hospital_group_id);
    }

    switchPatient(payload, callToken)
      .then(function (switch_response) {
        let switch_token = switch_response.headers.authorization;

        dispatch(setSwitchTokenReducer(switch_token));
        dispatch(setSelectedUserReducer(switch_response.data.user));

        getProfile(switch_token)
          .then(function (profile_response) {
            if (profile_response && profile_response.data) {
              dispatch(setUserProfileReducer(profile_response.data.user));
              setMemberSelect(profile_response.data.user);
            }
          }).catch(function (err) { });
      }).catch(function (err) { });
  }

  function getOTP() {
    let token = null;
    if (switchToken) {
      token = switchToken;
    } else {
      token = callToken;
    }

    getOTPWithPayloadTokenBased({ isd_code: 91, uid: searchText ? searchText : formik.values.mobile, secondary_request: 1 }, token)
      .then(function (otp_response) {
        let otp_res = otp_response.data;

        if (otp_res) {
          if (otp_res.errors && otp_res.errors.length > 0) {
            return toast.error(otp_res.errors[0].message, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false
            });
          } else {
            setShowForm('otp');
            setIsResend(true);
            setTimeLeft(30);
          }
        }
      })
      .catch(function (error) { });
  }

  function verifyOTP() {
    let accessToken = null;
    if (addToken !== null) {
      accessToken = addToken;
    } else {
      if (switchToken) {
        accessToken = switchToken;
      } else {
        accessToken = callToken;
      }
    }

    if (switchToken !== undefined || switchToken !== null) {
      let vPayload = null;
      if (patientId) {
        vPayload = {
          isd_code: "91",
          otp: currentOTP,
          uid: searchText ? searchText : formik.values.mobile,
          family_member_id: patientId,
          secondary_request: 1
        }
      } else {
        vPayload = {
          isd_code: "91",
          otp: currentOTP,
          uid: searchText ? searchText : formik.values.mobile,
          parent_id: currentUserId,
          secondary_request: 1
        }
      }

      verifyMobileOTPTokenBased(vPayload, accessToken)
        .then(function (verify_response) {
          let verify_res = verify_response.data;

          if (verify_res && verify_res.errors) {
            return toast.error(verify_res.errors[0].message, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false
            });
          }

          if (showForm === 'last_otp') {
            if (!isUpdate && (verify_res && verify_res.users)) {
              // let tempLocalUser = { ...selectedLocalUser };
              // tempLocalUser.dependents = verify_res.users[0].dependents;
              dispatch(setSelectedUserReducer(verify_res.users[0]));
            }
            // dispatch(setSelectedUserReducer(tempLocalUser));
            setModal(false);
            handleClose();
          } else {
            setAddToken(verify_response.headers.authorization);
            setSearchedUserList(verify_res.search_object);
            setShowForm('search_list');
          }

          setCurrentOTP('');
        }).catch(function (error) { });
    } else {
      verifyMobileOTP({ isd_code: "91", otp: currentOTP, uid: searchText })
        .then(function (verify_response) {
          let verify_res = verify_response.data;

          if (verify_res && verify_res.errors) {
            return toast.error(verify_res.errors[0].message, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false
            });
          }

          if (showForm === 'last_otp') {
            if (!isUpdate && (verify_res && verify_res.users)) {
              // let tempLocalUser = { ...selectedLocalUser };
              // tempLocalUser.dependents = [...tempLocalUser.dependents, { ...verify_res.users[0].dependents }];
              dispatch(setSelectedUserReducer(verify_res.users[0]));
            }
            // dispatch(setSelectedUserReducer(tempLocalUser));
            setModal(false);
            handleClose();
          } else {
            setAddToken(verify_response.headers.authorization);
            setSearchedUserList(verify_res.search_object);
            setShowForm('search_list');
          }

          setCurrentOTP('');
        }).catch(function (error) { });
    }
  }

  function setFormForAddMember(id) {
    let payload = `?family_member_id=` + id + `&dependence=true`;
    getProfileWithPayload(payload, addToken)
      .then(function (profile_response) {
        let profile_res = profile_response.data;

        if (profile_res) {
          if (profile_res.errors) {
            return toast.error(profile_res.errors[0].message, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false
            });
          }

          if (profile_res.user) {
            formik.setFieldValue('family_member_id', profile_res.user.id);
            formik.setFieldValue('firstName', profile_res.user.first_name);
            formik.setFieldValue('lastName', profile_res.user.last_name);
            formik.setFieldValue('mobile', profile_res.user.mobile_number);
            // formik.setFieldValue('emergencyContact', profile_res.user.emergency_contact);
            setDOB(profile_res.user.dob ? new Date(profile_res.user.dob) : new Date());
            formik.setFieldValue('email', profile_res.user.email);
            formik.setFieldValue('addressOne', profile_res.user.address_1);
            formik.setFieldValue('addressTwo', profile_res.user.address_2 == null? '': profile_res.user.address_2);
            formik.setFieldValue('pincode', profile_res.user.pin_code == null? '': profile_res.user.pin_code);
            formik.setFieldValue('gender', profile_res.user.gender ? genderList.indexOf(profile_res.user.gender) : 0);
            formik.setFieldValue('marital_status', profile_res.user.marital_status);
            formik.setFieldValue('country', profile_res.user.country_name);

            formik.setFieldValue('state', profile_res.user.state_name);
            stateList(profile_res.user.country_name, 'direct');

            formik.setFieldValue('city', profile_res.user.city_name);
            cityList(profile_res.user.state_name, 'direct');

            setShowForm('manage');
            setIsUpdate(true);
          }
        }
      }).catch(function (err) { });
  }

  function getJWTtoken() {
    if (typeof window !== "undefined") {
      if (switchToken) {
        return switchToken;
      } else {
        return callToken;
      }
    } else if (callToken === undefined) {
      return null;
    } else {
      return null;
    }
  }

  function bookAppointmentHandle() {
    function splitAtFirstSpace(str) {
      if (!str) return [];
      var i = str.indexOf(" ");
      if (i > 0) {
        return [str.substring(0, i), str.substring(i + 1)];
      } else return [str];
    }

    let timeSlotFormated = splitAtFirstSpace(timeSlot[0]);
    let timeSlotAccurate = timeSlotFormated[0];

    let JWTresponse = getJWTtoken();




    let payload= {
      booking_date: timeSlotAccurate,
      consult_type: consultT,
      doctor_id: docId,
      hospital_id: hospitalId,
      timeslot_id: timeSlot[1],
      // reason_code: reasonVal,
      web_source: web_source,
      source_url: window.location.origin + APP_SUB_FOLDER
    }

    let currentSelectedID = parseInt(document.querySelector('[name="familyMember"]:checked').value);
    let isMember = (document.querySelector('[name="familyMember"]:checked')).getAttribute("data-rel");
    if(isMember == "member"){
      payload.family_member_id = currentSelectedID;
    }

    if(reschedule){
      payload.booking_id = reschedule.id;
    }
    if(reasonVal && !reschedule){
      payload.reason_code = reasonVal;
    }

    bookAppointmentHospital(payload, JWTresponse)
      .then(function (response) {
        let res = response.data;
        setBookSuccess(true);
        setBookAppointRes(res);
        setErrorFlag(false);
        setBookSuccessTime(true);
        setReasonVal(null);
        setReasonStatus(false);
        setSpinnerResponse(false)

        if (res && response.statusText == "OK" && reschedule) {
          return showToast("Thank You, Your Appointment has been rescheduled on " + res.booking_date + " at " + formatAMPM(res.booking_slot_time));
        }

        if (res && res.errors && res.errors.length > 0) {
          setBookAppointRes(null);
          return showError(res.errors[0].message)
        }

        let currentSelectSlot =  document.querySelector('#slotID_'+ timeSlot[1] + ' button');
        // currentSelectSlot.classList.add('selected');
        currentSelectSlot.disabled = true;      



      })
      .catch(function (err) { });
  }

  const formik = useFormik({
    initialValues: {
      family_member_id: 0,
      firstName: "",
      lastName: "",
      mobile: "",
      emergencyContact: "",
      email: "",
      addressOne: "",
      addressTwo: "",
      pincode: "",
      dob: new Date(),
      gender: "0",
      relation: "Father",
      marital_status: "Single",
      country: "India",
    },

    onSubmit: (values, helpers) => {
      if (isUpdate) {
        let payload = {
          family_member_id: values.family_member_id,
          first_name: values.firstName,
          last_name: values.lastName,
          full_name: values.firstName + ' ' + values.lastName,
          gender: values.gender,
          dob: formattedDate(DOB),
          isd_code: "91",
          mobile_no: values.mobile,
          marital_status: values.maritalStatus,
          relation: values.relation,
          emergency_contact: values.emergencyContact,
          email: values.email,
          address_1: values.addressOne,
          address_2: values.addressTwo,
          country: values.country,
          state: values.state,
          city: values.city,
          pin_code: values.pincode,
          // profile_picture: profile,
        };

        updateFamilyMember(payload, addToken)
          .then(function (x_response) {
            let x_res = x_response.data;

            if (x_res.errors) {
              x_res.errors.map((e) => {
                return toast.error(e.message, {
                  position: "top-right",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: false
                });
              })
              return false;
            }

            if (x_res && x_res.user) {
              // let tempLocalUser = { ...selectedLocalUser };
              // tempLocalUser.dependents = [...tempLocalUser.dependents, { ...x_res.user }];
              getProfile(addToken)
                .then(function (profile_response) {
                  if (profile_response && profile_response.data) {
                    dispatch(setSelectedUserReducer(profile_response.data));
                  }
                }).catch(function (err) { });

              setModal(false);
              handleClose();
            }
          }).catch(function (err) { });
      } else {
        let payload = {
          first_name: values.firstName,
          last_name: values.lastName,
          full_name: values.firstName + ' ' + values.lastName,
          gender: values.gender,
          dob: formattedDate(DOB),
          isd_code: "91",
          mobile_no: values.mobile,
          marital_status: values.maritalStatus,
          relation: values.relation,
          emergency_contact: values.emergencyContact,
          email: values.email,
          address_1: values.addressOne,
          address_2: values.addressTwo,
          country: values.country,
          state: values.state,
          city: values.city,
          pin_code: values.pincode,
          // profile_picture: profile,
        };

        let token = null;
        if (switchToken) {
          token = switchToken;
        } else {
          token = callToken;
        }

        addFamilyMember(payload, token)
          .then(function (x_response) {
            let x_res = x_response.data;

            if (x_res.errors) {
              x_res.errors.map((e) => {
                return toast.error(e.message, {
                  position: "top-right",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: false
                });
              })
              return false;
            }

            setShowForm('last_otp');
            setPatientId(x_res.patient_id);
          }).catch(function (err) { });
      }
    },
  });

  function stateList(event, mode) {
    if (mode === 'indirect') {
      event = event.target.value;
      formik.setFieldValue("country", event);
    }

    if (countries && event) {
      let countryIndex = countries.map(x => x.n).indexOf(event);
      setAllState(countries[countryIndex]['s']);
    }
  }

  function cityList(event, mode) {
    if (mode === 'indirect') {
      event = event.target.value;
      formik.setFieldValue("state", event);
    }

    if (countries && allState && event) {
      let countryIndex = countries.map(e => e.n).indexOf(formik.values.country);
      let stateIndex = allState.map(e => e.n).indexOf(event);
      setAllCity(countries[countryIndex]['s'][stateIndex]['c']);
    }
  }

  function formattedDate(x) {
    let d = new Date(x);
    return [d.getDate(), d.getMonth() + 1, d.getFullYear()]
      .map(n => n < 10 ? `0${n}` : `${n}`).join('/');
  }

   const reasonCodeModalClose=()=>{
    setReasonCodeModal(false)
   }



  function continueBooking() {
    setSpinnerResponse(true)

    if(reasonCode == 1 && reasonStatus == false && reasonVal == null){     
       
      reasonBookingCall( docId, hospitalId, switchToken ).then(function (response) { 

   
        if(response.data.length > 0){
          setReasonCodeModal(true)    
          setSpinnerResponse(false)
          setReasonList(response.data)
        }else{
          setReasonVal(null);
          setReasonStatus(true);
          reasonConfirm();
        }
        
      }).catch(function (err) {
              console.log(err);
          });
    }else{
      
      setReasonCodeModal(false)   
      setProfileSelect(true);
      bookAppointmentHandle();
    }
     
  }

  const handleReasonChange = (id)=>{
    setReasonVal(id);
    setReasonStatus(true);

  }

  const reasonConfirm=()=>{
    
    setReasonStatus(false);
    setReasonCodeModal(false)
    setProfileSelect(true);
    bookAppointmentHandle(); 

  }

  const [timeLeft, setTimeLeft] = useState(30);
  const [isResend, setIsResend] = useState(false);

  useEffect(() => {
    let timer = setInterval(() => {
      if (isResend) {
        if (timeLeft > 0) {
          setTimeLeft(timeLeft - 1)
        } else {
          clearInterval(timer);
          setIsResend(false);
          setTimeLeft(30);
        }
      }
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  });
  const settingsprofile = {
    dots: false,
    infinite: false,
    speed: 1000,
    // slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: false,
    variableWidth: true, 
  };


  function showError(str) {
    toast.error(str, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false
    });
  }

  function showToast(str) {
    toast.success(str, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false
    });
  }

  
  return (
    <React.Fragment>
      <div className="row mhc-tabs mt-3">
        <div className="selectProfile">
          <div className={`profileList mb-3 ${loggedUsers?.length > 1 ? "border border-light" : ""}`}>
            {/* <Tabs activeKey={currentUserId} onSelect={(k) => { selectSwitch(k); resetBooking() }}>
              {
                loggedUsers?.map((item) => (
                  <Tab key={item.id} eventKey={item.id} title={item.full_name}>
                    <div className="row">
                      {errorflag === true ? (<h6 style={{ color: "red" }}>*{error}</h6>) : null}
                    </div>
                  </Tab>
                ))
              }
            </Tabs> */}
            <nav className="nav nav-pills nav-justified custom-nav">
                <Slider {...settingsprofile}> 
                
                {
                loggedUsers?.map((user) => (
                  <button type="button" disabled={reschedule} className={`btn ${'currentActive_'+currentUserId=== 'currentActive_'+user.id ?  "active":''}`} key={user.id} onClick={(k) => { selectSwitch(user.id); }}>{user.full_name}</button>
                ))
              }
                 
                
                </Slider>
                </nav>
          </div>
           
          <div className="row">
            
            {
       
              selectedLocalUser &&
              (<div className="col-lg-6 mb-3" key={selectedLocalUser.id}>
                <div className="form-check mhc-bordered-checkbox">
                  <input
                    data-rel="self"
                    className="form-check-input"
                    type="radio"
                    name="familyMember"
                    value={selectedLocalUser.id}
                    disabled={reschedule && rescheduleUser!=selectedLocalUser.id}
                    checked={(currentMemberId === selectedLocalUser.id) || (reschedule && rescheduleUser === selectedLocalUser.id)}
                    onChange={() => { setCurrentMemberId(selectedLocalUser.id); setMemberSelect(selectedLocalUser); resetBooking() }}
                  />
                  <label className="form-check-label" htmlFor={selectedLocalUser.id}>
                    <div className="d-flex align-items-center conent">
                      <div className="imgholder">
                        <img src={selectedLocalUser.profile_image} alt="" />
                      </div>
                      <p className="m-0">
                        <span className="fw-bold">{selectedLocalUser.full_name}</span>
                        <br />
                        <small>{selectedLocalUser.gender}, {calculateAge(selectedLocalUser.dob)} (Self)</small>
                      </p>
                    </div>
                  </label>
                </div>
              </div>)
            }

            {
              currentFamilyMembers==null ? <LodingComponent className="mhc-Loding-minHeight" />:
              currentFamilyMembers?.map((item) => (
                <div className="col-lg-6 mb-3" key={item.id}>
                  <div className="form-check mhc-bordered-checkbox">
                    <input
                      data-rel="member"
                      className="form-check-input"
                      type="radio"
                      name="familyMember"
                      value={item.id}
                      checked={(currentMemberId === item.id) || (reschedule && rescheduleUser === item.id)}
                      disabled={reschedule && rescheduleUser!=item.id}
                      onChange={() => { setCurrentMemberId(item.id); setMemberSelect(item); resetBooking() }}
                    />
                    <label className="form-check-label" htmlFor={item.id}>
                      <div className="d-flex align-items-center conent">
                        <div className="imgholder">
                          <img src={item.profile_image} alt={item.full_name} />
                        </div>
                        <p className="m-0">
                          <span className="fw-bold">{item.first_name} {item.last_name}</span>
                          <br />
                          <small>{item.gender}, {calculateAge(item.dob)} ({item.relation})</small>
                        </p>
                      </div>
                    </label>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
            {
              loggedUsers !== null ? <div className="col-12 d-flex justify-content-end">
              {/* <button className="btn btn-outline-secondary rounded-1" onClick={handleShow}>Add Family Member</button> */}
              <DashBoardFamilyMember className={`addFamily-profile ${reschedule ? "d-none" : "d-block"}`} />
              <Link to="/my-bookings" className={`btn btn-outline-secondary rounded-1 ms-2 ${reschedule ? "d-block" : "d-none"}`}>Replan</Link>
         
              {
                (currentMemberId && consultT && timeSlot) === undefined ? (
                  <button className={`btn btn-primary rounded-1 ms-2 ${btnDisplay === true ? "d-block" : "d-none"}`} disabled>Confirm</button>
                ) : (
                  <button className={`btn btn-primary rounded-1 ms-2 ${btnDisplay === true ? "d-block" : "d-none"}`}
                    onClick={() => continueBooking()}>Confirm   {spinnerResponse == true? <SpinnerComponent className="spinnerfix" />: null}</button>
                )
              }
            </div> : ""
            }
      </div>

      <Modal show={reasonCodeModal} onHide={reasonCodeModalClose} className="reason-modal" backdrop="static">
            <Modal.Header 
              //closeButton
            >
            Reason for Visit
                </Modal.Header>
                <Modal.Body className="pt-0">
           
                  <div>
                  <ul className="list-unstyled mb-0 ">
                 {
                  reasonList==undefined? '':
                  reasonList.map((item, id)=>{
                  
                    return(
                      <li key={id}>
                      <div className="form-check mt-3 cursor-pointer">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id={item.id}
                          defaultValue={item.id}
                          // defaultChecked={item.id === reasonCodeId}
                          onChange={(e) => setReasonVal(e.target.value)}
                        />
                        <label className="form-check-label cursor-pointer" htmlFor={item.id}>{item.reason}</label>
                      </div>
                       </li>
                    )
                  })
                 }
                
                </ul>
                  </div>
                  <div className="w-100 text-center">
                  <button type="submit" className="btn btn-custom rounded-1  mt-3" disabled={reasonVal===null ? true:false} onClick={()=>reasonConfirm()}>Confirm </button>
                  </div>
                    </Modal.Body>
                    </Modal>


      <ToastContainer />
    </React.Fragment>
  );
};

export default FamilyMember;