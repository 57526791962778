import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import antiClock from "../../assets/images/anticlock.png";
import { getOTP, requestPin, savePIN, switchPatient, verifyMobileOTP, verifyPIN } from "../../actions/post-call";
import { getProfile } from "../../actions/get-calls";

import { setCallTokenReducer } from "../../redux/slice/call-token";
import { setSwitchTokenReducer } from "../../redux/slice/switch-token";
import { setAllUsersReducer } from "../../redux/slice/all-users";
import { setSelectedUserReducer } from "../../redux/slice/selected-user";
import { setUserProfileReducer } from "../../redux/slice/user-profile";
import SpinnerComponent from "../spinnerComponent";
import { APP_SUB_FOLDER, is_group_exist, showUhidLogin } from "../../utils/constant";
import OTPInput from "react-otp-input";

const LoginUser = ({ registeredState, setOtpVerified, setMobile, setUhidNo }) => {
  const currentLocation = useSelector((state) => state.currentLocation.value);
  const[loginHome, setLoginHome]=useState(true);
  const[createPinScreen, setCreatePinScreen]=useState(false);
  const[loginPinScreen, setLoginPinScreen]=useState(false);
  const [pinSetScreen, setPinSetScreen]=useState(false)


  const [countryCode, setCountryCode] = useState("");
  const [mobileNumber, setMobileNumber] = useState('');
  const [uhid, setUhid]=useState("")
  const [uhidType, setUhidType ]=useState()
  const [isDisabledMob, setIsDisabledMob] = useState(false);
  const [isDisabledUHID, setIsDisabledUHID] = useState(false);
  const [otpMessage, setOtpMessageTag] = useState(false);
  const [otpMessageText, setOtpMessageText] = useState("");
  const [otpM, setOtpM] = useState();
  const [otpRe, setOtpRe] = useState(false);
  const [otpNum, setOtpNum] = useState();
  const [pinNum, setPinNum] = useState('');
  const [pinsetNum, setPinsetNum]=useState("")
  const [isOtpBaseLogin, setIsOtpBaseLogin]=useState(false)
  const [numberEmpty, setNumberEmpty] = useState(false);
  const [uhidEmpty, setUhidEmpty] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [timeLeft, setTimeLeft] = useState(30);
  const [isResend, setIsResend] = useState(false);
  const [optErrorMsg, setOptErrorMsg] = useState("");
  const [spinnerResponse, setSpinnerResponse] = useState(false);
  const [sendOtpResponse, setSendOtpResponse]=useState(false)
  const [spinnerResponseverify, setSpinnerResponseverify]=useState(false)
  const [spinnerResponsesetPin, setSpinnerResponsesetPin]=useState(false)
  const [callKey, setCallKey] = useState(null);
  const dispatch = useDispatch();
  const selectedUser = useSelector((state) => state.selectedUser.value);

  useEffect(() => {
    if (selectedUser) {
      return registeredState(true);
    }
  }, [selectedUser]);
  function disAbleMobileField(e){
    const uhidValue=e.target.value
    uhidValue == "" ? setIsDisabledMob(false): setIsDisabledMob(true)
    uhidValue !=="" ? setUhidType("uhid"):setUhidType("")
  }
  function disAbleUhidField (e){
    const mobValue=e.target.value
    mobValue  == "" ? setIsDisabledUHID(false): setIsDisabledUHID(true)
    mobValue !=="" ? setUhidType("Mobile_no"):setUhidType("")
  }

  function getMobileCheck() {
    setSendOtpResponse(true)
    // if (showOTPForm) {
    //   document.querySelector("#sendOTP").value = "";
    //   setOptErrorMsg("");
    // }
    if ((mobileNumber == "" || mobileNumber.length != 10) && isDisabledMob == false) {
      setNumberEmpty(true);
      setUhidEmpty(false);
      setSendOtpResponse(false)
    } else if ((uhid == "" || uhid.length <= 1) && isDisabledUHID == false) {
      setUhidEmpty(true);
      setNumberEmpty(false);
      setSendOtpResponse(false)
    }    else{
      let getValue = uhidType === "uhid" ? uhid : mobileNumber;
      requestPin(getValue, uhidType,  countryCode, currentLocation.hospital_group_id, currentLocation.hospitalID)
      .then(function (response) {    
   
        let avialblePin=response.data.is_pin_available
        if(avialblePin===false){
          let getValue = uhidType === "uhid" ? uhid : mobileNumber;
          getOTP(getValue, uhidType, currentLocation.hospital_group_id)
          .then(function (login_response) {
          
            if (login_response.status==200){            
              const login_text="The OTP has been sent to the mobile number "
              setLoginHome(false)
              setCreatePinScreen(true)
              setLoginPinScreen(false);
              setOtpMessageTag(true)             
              setOtpMessageText(login_text + login_response.data.mobile_number )
              setIsOtpBaseLogin(false)
              setPinSetScreen(false)
              setSendOtpResponse(false)
              setIsResend(true);
            }
            else{
              setOtpRe(true)      
              setOtpM(login_response.data.errors[0].message)           
              setCreatePinScreen(false)
              setLoginHome(true)
              setLoginPinScreen(false);
              setPinSetScreen(false)
              setSendOtpResponse(false)
              registeredState(false);
              setMobile(mobileNumber);
              setUhidNo(uhid)
            }
          })
        }
        else{
         
          setLoginPinScreen(true);
          setLoginHome(false)
          setCreatePinScreen(false)
          setPinSetScreen(false)
          setSendOtpResponse(false)
        }
    
        
      })
      .catch(function () { });
    }
  }

  const loginVerifyPIN =()=>{
    setSpinnerResponse(true)
    verifyPIN(mobileNumber, pinNum,  currentLocation.hospital_group_id, currentLocation.hospitalID)
    .then(function (response) {    
      let res = response.data;
   
      setSpinnerResponse(false)
      setOptErrorMsg('');
      if(res.errors){
        setOptErrorMsg(res.errors[0].message)
      }
      else{
        setOtpVerified(true);
        setOtpRe(false);
        setOtpMessageTag(false);
        // setCurrentUserId(res.users[0].id);
        setOtpMessageText('');
        dispatch(setCallTokenReducer(response.headers.authorization));
        dispatch(setAllUsersReducer(res.users));
       
        if (res && res.users && res.users.length == 1) {
          dispatch(setSwitchTokenReducer(response.headers.authorization));
          dispatch(setSelectedUserReducer(res.users[0]));
          // navigate('/my-dashboard');
     
        }
        else{
          switchUser(res.users[0].id, response.headers.authorization);
        }
      
      }
   
   
    })
    .catch(function () { });
  }


  const getOtpResend=()=>{
    let getValue = uhidType === "uhid" ? uhid : mobileNumber;
          getOTP(getValue, uhidType, currentLocation.hospital_group_id)
    .then(function (login_response) {
      const login_text="The OTP has been sent to the mobile number "
      setOtpMessageTag(true)             
      setOtpMessageText(login_text + login_response.data.mobile_number )
      setIsResend(true);
      setTimeLeft(30);
    })
    .catch(function () { });
  }
  const forgotPin=()=>{
    setIsOtpBaseLogin(false)
    getOtpResend()
    setLoginHome(false)
    setCreatePinScreen(true)
    setLoginPinScreen(false);
    setOptErrorMsg('');
  }
  const loginUsingOTP=()=>{
    setIsOtpBaseLogin(true)
    getOtpResend()
    setLoginHome(false)
    setCreatePinScreen(true)
    setLoginPinScreen(false);
    setOptErrorMsg('');
  }
  function verifiyOtp() {
    setSpinnerResponse(true);
    var payload = new FormData();
    payload.append("hospital_group_id", currentLocation?.hospital_group_id);
    payload.append("registration_source", 1);
    payload.append('enterprise_id', currentLocation?.hospital_group_id);
    if (uhidType === "uhid") {
      payload.append('type', 'uhid');
      payload.append('otp', otpNum);
      payload.append('uid', uhid);
    } else {
      payload.append('isd_code', 91);
      payload.append('otp', otpNum);
      payload.append('uid', mobileNumber);
    }
    if(isOtpBaseLogin==true){
      setSpinnerResponseverify(true)
      verifyMobileOTP(payload)
        .then(function (response) {
          let res = response.data;
          setSpinnerResponseverify(false)
    
          setOptErrorMsg('');
          if(res.errors){
            setOptErrorMsg(res.errors[0].message)
          }
          else{
            setOtpVerified(true);
            setOtpMessageTag(false);
            setOtpMessageText('');
    
            dispatch(setCallTokenReducer(response.headers.authorization));
            dispatch(setAllUsersReducer(res.users));
    
            setOtpRe(false);
            // setCurrentUserId(res.users[0].id);
    
            if (res && res.users && res.users.length == 1) {
              dispatch(setSwitchTokenReducer(response.headers.authorization));
              dispatch(setSelectedUserReducer(res.users[0]));
              // navigate('/my-dashboard');
            
            }
              else{
                switchUser(res.users[0].id, response.headers.authorization);
              }
           
  
              // navigate('/my-dashboard');
           
           
          }
       
        })
        .catch(function (err) { });
    }
    else{
      setSpinnerResponseverify(true)
      verifyMobileOTP(payload)
        .then(function (response) {    
          let res = response.data;
          setSpinnerResponseverify(false)
          setOptErrorMsg('');
          if(res.errors){
            setOptErrorMsg(res.errors[0].message)
          }
          if (response.status==200){    
            setLoginPinScreen(false);
            setLoginHome(false)
            setCreatePinScreen(false)
            setPinSetScreen(true)
          }
        })
        .catch(function (err) { });

    }
  }
  const pinSetlogin=()=>{
    setSpinnerResponsesetPin(true)
    savePIN(mobileNumber, pinsetNum,  currentLocation.hospital_group_id, currentLocation.hospitalID)
    .then(function (response) {
   
      let res = response.data;
   
      setSpinnerResponsesetPin(false)
   
      setOptErrorMsg('');
      if(res.errors){
        setOptErrorMsg(res.errors[0].message)
      }
      else{
        setOtpVerified(true);
        setOtpRe(false);
        setOtpMessageTag(false);
        setOtpMessageText('');
        dispatch(setCallTokenReducer(response.headers.authorization));
        dispatch(setAllUsersReducer(res.users));
       
        if (res && res.users && res.users.length == 1) {
          dispatch(setSwitchTokenReducer(response.headers.authorization));
          dispatch(setSelectedUserReducer(res.users[0]));
          // navigate('/my-dashboard');
     
        }
         else{
          switchUser(res.users[0].id, response.headers.authorization);
         }
      
      }
 
   
    })
    
    .catch(function (err) { });
   }

  function switchUser(id, token) {
    let payload = new FormData();
    payload.append("patient_id", id);
    if(is_group_exist == true){
      payload.append("hospital_group_id", currentLocation?.hospital_group_id);
      payload.append("group_id", currentLocation?.hospital_group_id);
     }
    switchPatient(
      payload,
      token ? token : callKey
    )
      .then(function (switch_response) {
        let switch_token = switch_response.headers.authorization;
        dispatch(setSwitchTokenReducer(switch_token));
        dispatch(setSelectedUserReducer(switch_response.data.user));

        getProfile(switch_token)
          .then(function (profile_response) {
            if (profile_response && profile_response.data) {
            
              dispatch(setUserProfileReducer(profile_response.data.user));
              // window.location.reload();

            }
          })
          .catch(function (err) {});

        setShowConfirmation(false);
      })
      .catch(function (err) {});
  }
  useEffect(() => {
    let timer = setInterval(() => {
      if (isResend) {
        if (timeLeft > 0) {
          setTimeLeft(timeLeft - 1)
        } else {
          clearInterval(timer);
          setIsResend(false);
          setTimeLeft(30);
        }
      }
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  });

  function showToast(str) {
    toast.error(str, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false
    });
  }

  function showError(str) {
    toast.error(str, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false
    });
  }

  return (
    <React.Fragment>
      {
        loginHome===true ?
     <>
      <ul className={showOTP === false ? "list-unstyled d-flex flex-wrap  list-login-width mb-0" : "list-unstyled d-flex flex-wrap  list-login mb-0"}>
        <li>
          <div className="form-floating">
            <input
              type="text"
              className="form-control"
              value={mobileNumber}
              maxLength="10"
              id="mobileNumber"
              name="mobileNumber"
              placeholder="Enter mobile number"      
                onChange={(e) => {
                  disAbleUhidField(e)
                  setMobileNumber(e.target.value);
                }}  
              onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }}
              disabled={isDisabledMob}
            />
            <label htmlFor="name">
              Mobile <sup>*</sup>
            </label>
          </div>
       
        </li>
        
            {
              showUhidLogin === true ?      <li className="uhid">
              <div className="d-flex align-items-center justify-content-center flex-column flex-md-row ms-0 ms-md-2 mt-4 mt-md-0">
              <p className="text-primary m-0 or-circle"><b>OR</b></p>
              <div className="form-floating w-100 mt-4 mt-md-0 ms-0 ms-md-2">
           
           <input
                       type="text"
                       className="form-control"
                       name="getType"
                       id="uhid"
                       placeholder="UHID"
                       maxLength="25" 
                       // onKeyDown={(e) => uhidNumberCheck()}               
                       onChange={(e) => {
                         disAbleMobileField(e)
                         setUhid(e.target.value);
                       }}                     
                       disabled={isDisabledUHID}
                     />
             <label htmlFor="name">
              UHID <sup>*</sup>
             </label>
           </div>
              </div>
             
            </li>:''
            
        }
     <li className="d-flex justify-content-center justify-content-md-start">
              <button className="btn btn-outline-primary btn-width mt-4 mt-md-0 rounded-1 mx-0 mx-md-2" onClick={()=>getMobileCheck()} >Continue {sendOtpResponse == true? <SpinnerComponent />: null} </button>
            </li>
 
      </ul>

                    {numberEmpty === true && (
                      <>
                        <p className="m-0 fs-7 text-primary mt-3">
                          Please enter a valid{" "}
                          {mobileNumber.length > 0 ? "Mobile" : "Mobile/UHID"} number.
                        </p>
                      </>
                    )}
               </>       :"  "  
                  }
               
      {
              loginPinScreen  == true ?<div className="mhc-loginscreen h-auto">
              
                   
             
                <div class="nav-reigster-form text-center ">
              
                  <p className="mt-4 text-secondary">Please enter your 4 digit PIN</p>
                <OTPInput
                    value={pinNum}
                    onChange={setPinNum}
                    numInputs={4}  
                    containerStyle="otp-wrapper"   
                    inputStyle="input"            
                    // renderSeparator={<span>-</span>}
                    renderInput={(props) => <input {...props} />}
                     />
                      <button className="btn btn-custom rounded-1 mt-3 px-5" onClick={()=>loginVerifyPIN()} >Verify Pin {spinnerResponse == true? <SpinnerComponent />: null}   </button>
                      {optErrorMsg !=''? <p className="text-primary mt-2">{optErrorMsg}</p> : ''}
                      <div className="d-flex mt-4">
                        <div><button className="btn text-primary text-decoration-none me-3 me-md-5" onClick={()=>forgotPin()} >Forgot Pin ?  </button></div>
                        <div><button className="btn text-primary text-decoration-none ms-3 ms-md-5" onClick={()=>loginUsingOTP()} >  Login Using OTP</button></div>
                      </div>
                      </div>
                </div>:null
              }
       
            {
              createPinScreen == true  ?     <div className="mhc-loginscreen h-auto">
                    <div class="nav-reigster-form text-center ">
                  
                
                {otpMessage === true && (<p className="m-0 fs-7 mt-4">{otpMessageText}</p>)}
                 
                  <OTPInput
                    value={otpNum}
                    onChange={setOtpNum}
                    numInputs={4}  
                    containerStyle="otp-wrapper"   
                    inputStyle="input"            
                    // renderSeparator={<span>-</span>}
                    renderInput={(props) => <input {...props} />}
                     />
                                   {optErrorMsg !=''? <p className="text-primary mt-2">{optErrorMsg}</p> : ''}
                     <div className="d-flex justify-content-center align-items-center">
                       <button className="btn btn-custom rounded-1 mt-3" onClick={()=>verifiyOtp()} >Verify OTP {spinnerResponseverify == true? <SpinnerComponent />: null}    </button>
                       
                       {
                        isResend ? (
                          <div className="anitClock ms-4 mt-3">
                            <img src={`${APP_SUB_FOLDER}${antiClock}`} className="anti-img" alt="" />
                            <p className="otp-time">{timeLeft}</p>
                          </div>
                        ) : (
                          <button  className="btn fs-6 text-decoration-none text-primary text-nowrap mt-3 ms-4" id="basic-addon1" onClick={()=>getOtpResend()} >Resend OTP</button>
                        )
                      }
                    </div>
                </div>
              </div>:null
            }
          {
            pinSetScreen===true? <div className="mhc-loginscreen h-auto">
            <div class="nav-reigster-form text-center ">
          
            <p className="mt-4  mb-0 text-secondary">Please set your 4 digit PIN</p>
         
          <OTPInput
            value={pinsetNum}
            onChange={setPinsetNum}
            numInputs={4}  
            containerStyle="otp-wrapper"   
            inputStyle="input"            
            // renderSeparator={<span>-</span>}
            renderInput={(props) => <input {...props} />}
             />
               <button className="btn btn-custom rounded-1 mt-3" onClick={()=>pinSetlogin()}  >Set PIN   {spinnerResponsesetPin == true? <SpinnerComponent />: null}   </button>
            
        </div>
      </div>:null
          }
      <ToastContainer />
    </React.Fragment>
  );
};

export default LoginUser;
